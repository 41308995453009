<template>
  <div class="dropdown">
    <base-button class="bars-button" @click="menuOpen = !menuOpen">
      <fa icon="bars" />
    </base-button>
    <transition name="appear">
      <div class="sub-menu" v-if="menuOpen">
        <div v-for="(item, i) in items" :key="i" class="menu-item">
          <base-button class="menu-button" @click="subButtonTapped(item.method)">
            {{ item.title }}
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  components: { BaseButton },
  data() {
    return {
      menuOpen: false,
    };
  },
  props: ["items"],
  methods: {
    subButtonTapped(method) {
      this.menuOpen = false;
      this.$emit(method);
    }
  }
};
</script>

<style>
.dropdown {
  position: relative;
}

.sub-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 5vh;
  right: 10%;
  background-color: #3f49e2;
  border-radius: 0px 0px 5px 5px;
}

.menu-item {
  font-size: small;
}

.bars-button,
.menu-button {
  margin: 0.25rem;
}

.appear-enter-active,
.appear-leave-active {
  transform: translateY(0);
  transition: all .2s ease-out;
}

.appear-enter-from,
.appear-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

@media (min-width: 768px) {
  .menu-item {
    font-size: medium;
  }
}
</style>
