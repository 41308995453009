<template>
  <div class="places-table">
    <li class="list-element">
      <h4 class="title">{{ place.title }}</h4>
      <p class="subtitle">{{ category(place.category) }}</p>
      <p class="distance">{{ place.nearbyDistance }}</p>
      <div class="delete">
        <abbr title="Aus Liste entfernen">
          <base-button class="flat" @click="$emit('deletePlace', place.id)">
            <fa icon="times"
          /></base-button>
        </abbr>
      </div>
      <div class="details">
        <abbr title="Details">
          <base-button class="flat" @click="$emit('showPlace', place.id)">
            <fa icon="arrow-right"
          /></base-button>
        </abbr>
      </div>
    </li>
  </div>
</template>

<script>
import categoryMixin from "../../mixins/category.js"

export default {
  mixins: [categoryMixin],
  props: ["place"],
  emits: ["deletePlace", "showPlace"],
};
</script>

<style scoped>
.places-table {
  border: 1px solid rgba(211, 211, 211, 0.3);
}

.places-table:nth-child(odd) {
  background-color: rgba(211, 211, 211, 0.2);
}

.list-element {
  padding: 0.3rem 0.3rem;
  display: grid;
  grid-template-columns: auto 4rem 2rem 2rem;
  grid-template-areas:
    "title distance delete details"
    "subtitle distance delete details";
}

.title,
.subtitle {
  margin: 0.3rem 0;
  padding: 0 0.5rem;
}

.title {
  grid-area: title;
}

.subtitle {
  font-size: small;
  grid-area: subtitle;
}

.distance {
  font-size: small;
  grid-area: distance;
}

/* //KD 210929 vorher hatte ich die drei icons in einem .button-Container zusammengefasst
    Dies klappte auf allen Simulatoren und auf Android, auf dem iPhone hatten die Buttons zu große
    Abstände (unabhängig vom Browser), daher das detailliertere Grid

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-area: buttons;
} */

.flat {
  margin: 0;
}

.distance,
.delete,
.details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete {
  grid-area: delete;
}

.details {
  grid-area: details;
}

@media (min-width: 768px) {
  .list-element {
    grid-template-columns: 4rem auto 2rem 2rem;
    grid-template-areas:
      "distance title delete details"
      "distance subtitle delete details";
  }
}
</style>
