<template>
  <div>
    <base-delete-dialog
      :show="willRequestFriendship"
      :titleCaption="'Freundschaft anfragen?'"
      :dialogText="'Willst du eine Freundschaft anfragen?'"
      :commitCaption="'Anfragen'"
      @commit="requestFriendship"
      @close="closeDialog"
    ></base-delete-dialog>
    <li class="list-element">
      <h4>{{ `${user.firstname} ${user.lastname} (${user.nickname})` }}</h4>
      <div class="request">
        <abbr title="Freundschaft anfragen">
          <base-button class="flat" @click="askForRequest">
            <fa icon="user-friends"
          /></base-button>
        </abbr>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      willRequestFriendship: false,
    };
  },
  props: ["user"],
  emits: ["requestFriendship"],
  methods: {
    askForRequest() {
      this.willRequestFriendship = true;
    },
    requestFriendship() {
      this.willRequestFriendship = false;
      this.$emit("requestFriendship", this.user.userId);
    },
    closeDialog() {
      this.willRequestFriendship = false;
    },
  },
};
</script>

<style scoped>
.list-element {
  padding: 0.1rem 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  grid-template-areas: "name request";
}
.flat {
  margin: 0;
}
.request {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-element:nth-child(odd) {
  background-color: rgba(211, 211, 211, 0.2);
}

.list-element:first-child {
  border-radius: 12px 12px 0 0;
}
.list-element:last-child {
  border-radius: 0 0 12px 12px;
}

/* .list-element:hover {
  background-color: #e2e1e1;
} */
</style>
