import jwt_decode from "jwt-decode";
let timer;

export default {
  tryLogin(context, auth0) {
    //KD after reloading the page I try to login again
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const email = localStorage.getItem("email");
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    const emailVerified = localStorage.getItem("emailVerified") === "true"; //Careful: be sure you get a real boolean value

    if (tokenExpiration == null) return;
    // ansonsten wird unten +tokenExpiration = 0, expiresIn negativ, und da beim Automatischen Logout
    // noch einmal App.vue gebaut wird, erneut ein tryLogin durchgeführt und damit eine Endlosschleife fabriziert

    const expiresIn = +tokenExpiration - new Date().getTime();
    if (expiresIn < 0) {
      localStorage.removeItem("token");
      context.dispatch("logout", auth0);
      return;
    }

    timer = setTimeout(() => {
      context.dispatch("logout", auth0);
    }, expiresIn);

    if (token && userId && email) {
      context.commit("setUser", {
        token: token,
        userId: userId,
        email: email,
      });
    }

    context.commit("setEmailVerified", emailVerified);
  },

  async login(context, auth0) {
    try {
      await auth0.loginWithPopup();
    } catch {
      throw Error("Fehler bei der Anmeldung, bitte versuche es später erneut.");
    }

    const tokenExpiration = auth0.idTokenClaims._rawValue.exp * 1000;
    const expiresIn = tokenExpiration - new Date().getTime();

    timer = setTimeout(() => {
      context.dispatch("logout", auth0);
    }, expiresIn);

    const token = auth0.idTokenClaims._rawValue.__raw;
    const userId = auth0.idTokenClaims._rawValue.sub;
    const email = auth0.idTokenClaims._rawValue.email;

    let userInfo = jwt_decode(token);
    const emailVerified = userInfo.email_verified;

    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiration", tokenExpiration);
    localStorage.setItem("userId", userId);
    localStorage.setItem("email", email);
    localStorage.setItem("emailVerified", emailVerified);

    context.commit("setUser", {
      token: token,
      userId: userId,
      email: email,
    });

    context.commit("setEmailVerified", emailVerified);
  },
  async logout(context, auth0) {
    const callback = window.location.origin + "/auth";

    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("emailVerified");

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null,
      email: null,
    });

    await auth0.logout({ returnTo: callback });
  },
};
