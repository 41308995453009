export default {
  isAuthenticated(state) {
    return state.emailVerified && !!state.token;
  },

  //TODO brauche ich alle drei?
  userId(state) { 
    return state.userId
  },
  email(state) {
    return state.email
  },
  token(state) {
    return state.token
  },  
  emailVerified(state) {
    return state.emailVerified
  }  
};
