class Place {
  constructor(title, subtitle, details, address, lat, lng, phone, link, category, id, googleImage, privacy, nearbyDistance) {
    this.title = title,
      this.subtitle = subtitle,
      this.details = details,
      this.address = address,
      this.lat = lat,
      this.lng = lng,
      this.phone = phone,
      this.link = link,
      this.category = category,
      this.id = id;
      this.googleImage = googleImage; 
      this.privacy = privacy;
      this.nearbyDistance = nearbyDistance; //only used in nearbyPosition
  }

  distTo(lat1, lon1) {
    const lat2 = this.lat;
    const lon2 = this.lng;
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
  }
}

export default Place;
