<template>
  <teleport to="body">
    <div v-if="show" @click="close" class="backdrop"></div>
    <transition name="dialog">
      <dialog open v-if="show">
        <header>
          <h2>{{ title }}</h2>
        </header>
        <section>
          <textarea ref="input" @keyup.enter="commit()" rows="5" class="chat-input" v-model.trim="inputValue" ></textarea>
        </section>
        <menu>
          <base-button @click="close">Abbrechen</base-button>
          <base-button @click="commit">Senden</base-button>
        </menu>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  updated() {
    this.$nextTick(() => {
      if(this.$refs.input) this.$refs.input.focus()
      })
      this.inputValue = ""
  },
  data() {
    return {
      inputValue: "",
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["close", "commit"],
  methods: {
    close() {
      this.$emit("close");
    },
    commit() {
      this.$emit("commit", this.inputValue);
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden; /* //KD 210926 damit der Header nicht rausragt */
  background-color: white;
}

header {
  background-color: #3f49e2;
  color: #ecec14;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

.chat-input {
  width: 100%;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

textarea {
  font: inherit;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
