<template>
  <header>
    <base-spinner class="dialog-spinner" v-if="showSpinner"></base-spinner>
    <nav>
      <h2>
        <router-link to="/">{{ title }}</router-link>
        <fa v-if="geoCoding" class="location" icon="location-arrow" />
      </h2>
      <ul>
        <li v-if="isAuthenticated">
          <router-link to="/map">Map</router-link>
        </li>
        <li v-if="isAuthenticated">
          <router-link to="/places">List</router-link>
        </li>
      </ul>
      <dropdown
        v-if="isAuthenticated"
        :items="services"
        @friends="friends"
        @logout="logout"
      ></dropdown>
    </nav>
  </header>
</template>

<script>
import Dropdown from "../ui/Dropdown.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      services: [
        {
          title: "Freunde",
          method: "friends",
        },
        {
          title: "Abmelden",
          method: "logout",
        },
      ],
      showSpinner: false,
    };
  },
  computed: {
    ...mapGetters(["friend", "myPlaces"]),
    title() {
      return this.myPlaces ? "Nice Places" : `${this.friend.firstname}s Places`;
    },
    geoCoding() {
      return this.$store.getters.geoCoding;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", this.$auth0);
    },
    async friends() {
      this.showSpinner = true;
      let response = await fetch(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/user`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        }
      );

      let resp = await response.json();
      this.showSpinner = false;
      if (resp) {
        this.$router.push("/friends")
      } else {
        this.$router.push("/user");
      }

      // if (response.status == 200) {
      //   this.$router.push("/friends");
      // } else {
      //   this.$router.push("/user");
      // }
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
  height: 10vh; /* synchronize with "grid-template-areas" in App.vue, -> README.md */
  background-color: #3f49e2;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}

header img {
  padding: 3pt;
  width: 10vh;
  height: 10vh; /* height and width has to be sycnchronized with "grid-template-areas" in App.vue, -> README.md */
}

header a {
  text-decoration: none;
  color: #ecec14;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

header h2 {
  display: flex;
  align-items: center;
}

.location {
  margin-right: 1rem;
  color: #ecec14;
  font-size: medium;
}

.sign-out {
  margin-top: 0.5em;
  color: #ecec14;
}

/* a:active, */
a:hover,
a.router-link-active {
  border: 1px solid #ecec14;
}

h2 a:hover,
h2 a:active,
h2 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  padding: 0;
  display: flex;
}

/* media (min-height: 51rem) { /* synchronize with media-query in App.vue  */
@media (min-width: 768px) {
  /* synchronize with media-query in App.vue */
  header {
    height: 7vh;
  }

  header img {
    height: 7vh; /* height has to be synchronized with "grid-template-areas" in App.vue, -> README.md */
    width: 7vh;
  }
}
</style>
