<template>
  <div class="place-list">
    <search-criteria @nearbySearch="nearbySearch" @back="back"></search-criteria>
    <h1 v-if="emptyResult">Nichts gefunden</h1>
    <ul>
      <nearby-list-item
        @deletePlace="removePlace"
        @showPlace="showPlace"
        v-for="place in nearbyPlaces"
        :key="place.id"
        :place="place"
      ></nearby-list-item>
    </ul>
  </div>
</template>

<script>
import NearbyListItem from "../../components/places/NearbyListItem.vue";
import SearchCriteria from "../../components/places/SearchCriteria";
import createPlaceMixin from "../../mixins/createPlace.js";

export default {
  components: {
    NearbyListItem,
    SearchCriteria,
  },
  data() {
    return {
      emptyResult: false,
      placesService: null,
      placeToEdit: null,
    };
  },
  computed: {
    center() {
      return this.$store.getters.center;
    },
    nearbyPlaces() {
      return this.$store.getters.nearbyPlaces;
    },
  },
  mixins: [createPlaceMixin],
  methods: {
    removePlace(id) {
      this.$store.dispatch("removeNearbyPlace", id);
    },
    showPlace(id) {
      let nearbyPlaces = this.$store.getters.nearbyPlaces;
      this.placeToEdit = nearbyPlaces.find((p) => p.id === id);
      const request = {
        placeId: id,
        fields: ["website", "international_phone_number", "formatted_address"],
      };
      this.placesService.getDetails(request, this.detailsCallback);
    },
    detailsCallback(googlePlace, status) {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        this.placeToEdit.link = googlePlace.website;
        this.placeToEdit.phone = googlePlace.international_phone_number;
        this.placeToEdit.address = googlePlace.formatted_address;
        this.$store.dispatch("updateNearbyPlace", this.placeToEdit);
        this.$router.push(`/places/nearby/${this.placeToEdit.id}`);
      }
    },
    nearbySearch(radius, type) {
      this.$store.dispatch("setNearbyPlaces", []);
      var request = {
        location: this.center,
        radius: radius,
        type: [type],
      };

      this.placesService = new window.google.maps.places.PlacesService(
        this.$store.getters.map
      );
      this.placesService.nearbySearch(request, this.nearbyCallback);
    },
    nearbyCallback(results, status) {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        const lat = this.center.lat();
        const lng = this.center.lng();
        let placesFound = [];
        for (let result of results) {
          if (result.business_status == "OPERATIONAL") {
            const place = this.createPlace(result);
            const dist = place.distTo(lat, lng);
            const kmDist = dist / 1000;
            const km = kmDist > 25 ? kmDist.toFixed(0) : kmDist.toFixed(2);
            place.nearbyDistance = km + " km";
            placesFound.push(place); //show only places that are not closed
          }
        }
        placesFound.sort((p1, p2) => {
          return p1.distTo(lat, lng) - p2.distTo(lat, lng);
        });
        this.$store.dispatch("setNearbyPlaces", placesFound);
      }
      this.emptyResult = this.nearbyPlaces.length == 0;
    },
    back() {
      this.$router.push("/map")
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

.place-list {
  background-color: white;
}

@media (min-width: 768px) {
  .place-list {
    min-width: 768px;
    max-width: 90vw;
    margin: auto;
  }
}
</style>
