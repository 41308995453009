<template>
  <div>
    <base-delete-dialog
      :show="willCancelFriendship"
      :titleCaption="'Freundschaft kündigen?'"
      :dialogText="'Willst du die Freundschaft wirklich kündigen?'"
      :commitCaption="'Kündigen'"
      @commit="cancelFriendship"
      @close="closeDialog"
    ></base-delete-dialog>
    <base-delete-dialog
      :show="willShowPlaces"
      :titleCaption="`Places von ${friend.firstname} anzeigen?`"
      :dialogText="`Willst du die Places von ${friend.firstname} anzeigen?`"
      :commitCaption="'Anzeigen'"
      @commit="showFriendsPlaces"
      @close="closeDialog"
    ></base-delete-dialog>
    <li class="list-element">
      <h4>{{ `${friend.firstname} ${friend.lastname} (${friend.nickname})` }}</h4>
      <div class="cancel">
        <abbr title="Freundschaft künfigen">
          <base-button class="flat" @click="askForCancel">
            <fa icon="times"
          /></base-button>
        </abbr>
      </div>
      <div class="show">
        <abbr title="Places des Freundes anzeigen?">
          <base-button class="flat" @click="askForPlaces">
            <fa icon="map-marker"
          /></base-button>
        </abbr>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      willCancelFriendship: false,
      willShowPlaces: false,
    };
  },
  props: ["friend"],
  emits: ["cancelFriendship", "showFriendsPlaces"],
  methods: {
    askForCancel() {
      this.willCancelFriendship = true;
    },
    askForPlaces() {
      this.willShowPlaces = true;
    },
    cancelFriendship() {
      this.willCancelFriendship = false;
      this.$emit("cancelFriendship", this.friend.userId);
    },
    showFriendsPlaces() {
      this.willShowPlaces = false;
      this.$emit("showFriendsPlaces", this.friend.userId, this.friend.firstname);
    },
    closeDialog() {
      this.willCancelFriendship = false;
      this.willShowPlaces = false;
    },
  },
};
</script>

<style scoped>
.list-element {
  padding: 0.1rem 1rem;
  display: grid;
  grid-template-columns: auto 2rem 2rem;
  grid-template-areas: "name show cancel";
}
.flat {
  margin: 0;
}
.show,
.cancel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-element:nth-child(odd) {
  background-color: rgba(211, 211, 211, 0.2);
}

.list-element:first-child {
  border-radius: 12px 12px 0 0;
}
.list-element:last-child {
  border-radius: 0 0 12px 12px;
}

/* .list-element:hover {
  background-color: #e2e1e1;
} */
</style>
