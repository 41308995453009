<template>
  <base-card>
    <li>
      <!-- //KD 210525 TODO Breite des Bildes vom Gerät abhängig machen -->
      <!-- <img :src="imageUrl" alt="Kein Bild :-(" :width="300"/> -->
      <div class="container">
        <img :src="imageUrl" @click="$emit('showDetails', imageUrl)" alt="Kein Bild :-(" />
        <base-button v-if="showTrashCan" class="flat" @click="$emit('deleteImage', imageId)">
          <fa icon="trash"
        /></base-button>
      </div>
    </li>
  </base-card>
</template>

<script>
import BaseCard from "../ui/BaseCard.vue";
export default {
  components: { BaseCard },
  props: ["imageUrl", "imageId"],
  emits: ["showDetails", "deleteImage"],
  computed: {
    showTrashCan() {
      return this.imageUrl.substring(0,27) != "https://maps.googleapis.com"  &&  this.$store.getters.myPlaces
    },
  }
};
</script>

<style scoped>
.container {
  position: relative;
}

.container, img {
  max-width: 80vw;
  display: block;
  margin: auto;
}

img {
  cursor: pointer;
}

.flat {
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 1%;
}

@media (min-width: 768px) {
  .container, img {
    max-width: 50vw;
  }

  .flat {
    right: 20px;
  }
}

</style>
