<template>
  <div class="search-card">
    <base-spinner class="dialog-spinner" v-if="showSpinner"></base-spinner>
    <input
      ref="autocomplete"
      type="search"
      placeholder="Name oder Adresse weltweit "
      onfocus="value = ''"
      @v-model="(event) => (address = event.target.value)"
    />
    <base-button class="nearby-button" @click.prevent="$emit('nearbySearch')"
      >Umkreis</base-button
    >
  </div>
</template>

<script>
import createPlaceMixin from "../../mixins/createPlace.js";
export default {
  mounted() {
    if (window.google) this.prepareAutoComplete()  
  },
  updated() {
    this.prepareAutoComplete();
  },
  mixins: [createPlaceMixin],
  props: ["update"],
  emits: ["nearbySearch", "setError"],
  data() {
    return {
      autocomplete: null,
      showSpinner: false,
    };
  },
  methods: {
    prepareAutoComplete() {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        { types: [] } // all types
      );
      this.autocomplete.addListener("place_changed", () => {
        var place = this.autocomplete.getPlace();
        if (!place.geometry) {
          this.$emit("setError", "Bitte wähle einen der vorgeschlagenen Places aus");
          return
        }
        this.showSpinner = true;
        this.createPlaceFromGooglePlace(place);
      });
    },
    async createPlaceFromGooglePlace(googlePlace) {
      const newPlace = this.createPlace(googlePlace);
      newPlace.googleImage = "available"; //have to get it every time from google, because not persistant
      if (newPlace == null) {
        this.$emit("setError", "Fehler beim Anlegen des Nice Place");
        this.showSpinner = false;
        return;
      }

      let places = this.$store.getters.places;
      let storedPlace = places.find((p) => p.id === newPlace.id);
      if (storedPlace == null) {
        // if not already stored
        try {
          await this.$store.dispatch("addPlace", newPlace);
        } catch (error) {
          this.$emit("setError", "Fehler beim Anlegen des Nice Place");
          return;
        }
      }
      this.showSpinner = false;

      const latLng = new window.google.maps.LatLng(newPlace.lat, newPlace.lng);
      this.$store.dispatch("setCenter", latLng);
    },
  },
};
</script>

<style scoped>
input {
  width: 60vw;
  margin-right: 1rem;
  height: 1.5rem;
  padding: 0.3rem;
  border: solid lightgrey 1px;
}

input:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3f49e2;
}

.search-card {
  position: relative;
  border: solid lightgrey 1px;
  padding: 0.5rem;
  background-color: rgb(242, 241, 240);
}

@media (min-width: 768px) {
  input {
    width: 50vw;
  }

  .search-card {
    padding: 0.7rem;
  }
}
</style>
