<template>
  <div class="friends-list">
    <base-spinner v-show="showSpinner" class="dialog-spinner"></base-spinner>
    <div class="newFriends" v-if="myPlaces && !showSpinner">
      <base-button class="newFriendsButton" @click="startChat"
        >Chat</base-button
      >
      <base-button class="newFriendsButton" @click="findFriend"
        >neue Freunde finden</base-button
      >
    </div>
    <div class="newFriends" v-if="!myPlaces">
      <base-button class="newFriendsButton" @click="loadPlaces"
        >Meine Places anzeigen</base-button
      >
    </div>
    <h2 v-if="noFriends && !showSpinner">Du hast noch keine Freunde</h2>
    <h2 v-if="friendsAvailable">Deine Freunde</h2>
    <div v-if="friendsAvailable">(Um die Places deiner Freunde zu sehen, klicke <span><fa icon="map-marker"
          /></span>)</div>
    <ul v-if="friendsAvailable">
      <friends-list-item
        @cancelFriendship="cancelFriend"
        @showFriendsPlaces="loadFriendsPlaces"
        v-for="friend in friendsCommitted"
        :key="friend.nickname"
        :friend="friend"
      ></friends-list-item>
    </ul>
    <h2 v-if="requestsPending">Du hast Freundschaftsanfragen:</h2>
    <ul v-if="requestsPending">
      <requested-list-item
        @cancelRequest="cancelFriend"
        @commitRequest="commitFriend"
        v-for="request in requests"
        :key="request.nickname"
        :request="request"
      ></requested-list-item>
    </ul>
  </div>
</template>

<script>
import FriendsListItem from "./FriendsListItem.vue";
import RequestedListItem from "./RequestedListItem.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  async created() {
    await this.setFriends();
  },
  components: {
    FriendsListItem,
    RequestedListItem,
  },
  data() {
    return {
      showSpinner: false,
    };
  },
  methods: {
    ...mapActions(["cancelFriendship", "commitRequest"]),
    async setFriends() {
      this.showSpinner = true;
      await this.$store.dispatch("getFriends");
      this.showSpinner = false;
    },
    findFriend() {
      this.$router.push("/users");
    },
    startChat() {
      this.$router.push("/chat");
    },
    async cancelFriend(userId) {
      this.showSpinner = true;
      await this.cancelFriendship(userId.substring(6));
      if (!this.myPlaces && userId === this.friend.userId) {
        this.loadPlaces();
      } // don't show places for rejected friends
      this.showSpinner = false;
    },
    async commitFriend(userId) {
      this.showSpinner = true;
      await this.commitRequest(userId.substring(6));
      this.showSpinner = false;
    },
    async loadFriendsPlaces(userId, firstname) {
      this.showSpinner = true;
      await this.$store.dispatch("loadFriendsPlaces", { userId, firstname });
      this.showSpinner = false;
      this.$router.push("/map");
    },
    async loadPlaces() {
      this.showSpinner = true;
      await this.$store.dispatch("loadPlaces");
      this.showSpinner = false;
      this.$router.push("/map");
    },
  },
  computed: {
    ...mapGetters(["friend", "friends", "myPlaces"]),
    friendsCommitted() {
      return this.friends.filter((f) => f.status === "committed");
    },
    requests() {
      return this.friends.filter((f) => f.status === "requested");
    },
    requestsPending() {
      return this.requests.length > 0;
    },
    friendsAvailable() {
      return this.friendsCommitted.length > 0;
    },
    noFriends() {
      return !this.friendsAvailable;
    },
  },
};
</script>

<style scoped>
ul {
  border: 1px solid rgba(211, 211, 211, 0.3);
  border-radius: 12px;
  list-style-type: none;
  padding: 0;
}

.friends-list {
  background-color: white;
  position: relative;
  margin: 1.5rem;
}

.newFriends {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.newFriendsButton {
  font-size: large;
  padding: 1rem;
}

@media (min-width: 768px) {
  .friends-list {
    min-width: 768px;
    max-width: 90vw;
    margin: auto;
  }
}
</style>
