<template>
  <div class="places-table">
    <base-delete-dialog
      :show="willDeletePlace"
      :titleCaption="'Nice Place löschen?'"
      :dialogText="'Willst du den Nice Place wirklich löschen?'"
      :commitCaption="'Löschen'"
      @commit="deletePlace"
      @close="closeDialog"
    ></base-delete-dialog>
    <li class="list-element">
      <h4 class="title">{{ place.title }}</h4>
      <p class="subtitle">{{ place.subtitle }}</p>
      <!-- //KD 210929 ich könnte die Klassen (delete, etc) in den nächsten Zeilen auch direkt dem abbr-Element zuordnen,
          mit dem div-Container fand ich es aber eleganter. Zu abbr gibt es auch Alternativen (z.B. tooltip): -->
      <p class="distance">{{ distanceToPosition }}</p>
      <div class="privacy" v-if="myPlaces && forFriends">
        <abbr title="für Freunde sichtbar">
          <fa icon="user-friends" />
        </abbr>
      </div>
      <div class="delete" v-if="myPlaces">
        <abbr title="Löschen">
          <base-button class="flat" @click="askForDelete">
            <fa icon="trash"
          /></base-button>
        </abbr>
      </div>
      <div class="map">
        <abbr title="Karte">
          <base-button class="flat" @click="$emit('showOnMap', place.id)"
            ><fa icon="map"
          /></base-button>
        </abbr>
      </div>
      <div class="details">
        <abbr title="Details">
          <base-button class="flat" @click="$emit('updatePlace', place.id)"
            ><fa icon="arrow-right"
          /></base-button>
        </abbr>
      </div>
      <!-- </div> -->
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      willDeletePlace: false,
    };
  },
  props: ["place"],
  emits: ["deletePlace", "updatePlace", "showOnMap"],
  methods: {
    deletePlace() {
      this.$emit("deletePlace", this.place.id);
      this.willDeletePlace = false;
    },
    askForDelete() {
      this.willDeletePlace = true;
    },
    closeDialog() {
      this.willDeletePlace = false;
    },
  },
  computed: {
    distanceToPosition() {
      const pos = this.$store.getters.currentPosition;
      const dist = this.place.distTo(pos.lat, pos.lng);
      const kmDist = dist / 1000;
      const km = kmDist > 25 ? kmDist.toFixed(0) : kmDist.toFixed(2);
      return km + " km";
    },
    myPlaces() {
      return this.$store.getters.myPlaces;
    },
    forFriends() {
      return this.place.privacy == "friends";
    },
  },
};
</script>

<style scoped>
.places-table:nth-child(odd) {
  background-color: rgba(211, 211, 211, 0.2);
}
.places-table:first-child {
  border-radius: 12px 12px 0 0;
}
.places-table:last-child {
  border-radius: 0 0 12px 12px;
}
.list-element {
  padding: 0.3rem 0.5rem;
  display: grid;
  grid-template-columns: 1.2rem auto 4rem 2rem 2rem 2rem;
  grid-template-areas:
    "privacy title distance  delete map details"
    "privacy subtitle distance  delete map details";
}

/* .places-table:hover {
  background-color: #e2e1e1;
} */

.title,
.subtitle {
  margin: 0.3rem 0;
  padding: 0 0.5rem;
}

.title {
  grid-area: title;
}

.subtitle {
  font-size: small;
  grid-area: subtitle;
}

.distance {
  font-size: small;
  grid-area: distance;
}

/* //KD 210929 vorher hatte ich die drei icons in einem .button-Container zusammengefasst
    Dies klappte auf allen Simulatoren und auf Android, auf dem iPhone hatten die Buttons zu große
    Abstände (unabhängig vom Browser), daher das detailliertere Grid

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-area: buttons;
} */

.flat {
  margin: 0;
}

.privacy,
.distance,
.delete,
.map,
.details {
  display: flex;
  align-items: center;
  justify-content: center;
}


.privacy {
  grid-area: privacy;
}

.delete {
  grid-area: delete;
}

.map {
  grid-area: map;
}

.details {
  grid-area: details;
}

@media (min-width: 768px) {
  .list-element {
    grid-template-columns: 2rem 4rem auto 2rem 2rem 2rem;
    grid-template-areas:
      "privacy distance title delete map details"
      "privacy distance subtitle delete map details";
  }
}
</style>
