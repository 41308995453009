<template>
  <div>
    <base-delete-dialog
      :show="willCancelRequest"
      :titleCaption="'Anfrage ablehnen?'"
      :dialogText="'Willst du die Anfrage wirklich ablehnen?'"
      :commitCaption="'Ablehnen'"
      @commit="cancelRequest"
      @close="closeDialog"
    ></base-delete-dialog>
    <li class="list-element">
      <h4>
        {{ `${request.firstname} ${request.lastname} (${request.nickname})` }}
      </h4>
      <div class="cancel">
        <abbr title="Anfrage ablehnen">
          <base-button class="flat" @click="askForCancel">
            <fa icon="times"
          /></base-button>
        </abbr>
      </div>
      <div class="commit">
        <abbr title="Anfrage akzeptieren">
          <base-button class="flat" @click="$emit('commitRequest', request.userId)">
            <fa icon="check"
          /></base-button>
        </abbr>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      willCancelRequest: false,
    };
  },
  props: ["request"],
  emits: ["cancelRequest", "commitRequest"],
  methods: {
    askForCancel() {
      this.willCancelRequest = true;
    },
    cancelRequest() {
      this.willCancelRequest = false;
      this.$emit("cancelRequest", this.request.userId);
    },
    closeDialog() {
      this.willCancelRequest = false;
    },
  },
};
</script>

<style scoped>
.list-element {
  padding: 0.1rem 1rem;
  display: grid;
  grid-template-columns: auto 2rem 2rem;
  grid-template-areas: "name cancel committ";
}
.flat {
  margin: 0;
}
.cancel,
.commit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-element:nth-child(odd) {
  background-color: rgba(211, 211, 211, 0.2);
}

.list-element:first-child {
  border-radius: 12px 12px 0 0;
}
.list-element:last-child {
  border-radius: 0 0 12px 12px;
}

/* .list-element:hover {
  background-color: #e2e1e1;
} */
</style>
