import { createStore } from 'vuex';
import authModule from './modules/auth/index.js';
import placesModule from './modules/places/index.js';
import friendsModule from './modules/friends/index.js';

const store = createStore({
  modules: {
    auth: authModule,
    places: placesModule,
    friends: friendsModule,
  },
});

export default store;