import axios from "axios";
import Place from "../../Place.js";
import placesJson from "../../../assets/niceplaces.json";

///KD 210516 In den folgenden Methoden nutze ich bewusst drei verschiedene Request-Techniken,
// um mit diesen Erfahrungen zu sammeln: fetch.then, try-catch und axios
// siehe auch hier: 3 Ways To Write Asynchronous Code in JavaScript - Learn Callbacks, Promises, and Async/Await in JavaScript in
// https://betterprogramming.pub/3-ways-to-write-asynchronous-code-in-javascript-b8d17e60df06

export default {
  loadPlacesFromJson(context) {
    //KD 220508 für Tests
    const places = placesJson.map((np) => {
      return new Place(
        np.title,
        np.subtitle,
        np.details,
        np.address,
        np.lat,
        np.lng,
        np.phone,
        np.link,
        np.category,
        np.id,
        np.googleImage,
        np.privacy
      );
    });
    //falls ich die aktuelle Position habe, sortiere ich in der Liste ausgerichtet danach, ansonsten nach dem Dom
    const lat = parseFloat(context.getters.currentPosition.lat) ?? 50.941302;
    const lng = parseFloat(context.getters.currentPosition.lng) ?? 6.957189;
    places.sort((p1, p2) => {
      return p1.distTo(lat, lng) - p2.distTo(lat, lng);
    });
    context.commit("setPlaces", places);
    // for (let place of places) { //KD Wiederverwendbar, wenn man mit allen Places etwas machen möchte
    //   context.dispatch("addPlace", place)
    //   console.log(place.title)
    //   }
  },
  async loadPlaces(context) {
    const response = await fetch(
      `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places`,
      {
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      }
    ).catch(() => {
      throw "Fehler beim Laden der Nice Places. Bitte versuche es später erneut.";
    });

    if (!response.ok) {
      throw "Fehler beim Laden der Nice Places. Bitte versuche es später erneut.";
    }

    const responseData = await response.json();
    const places = responseData.map((np) => {
      //  if (np.imageUrl != "" && np.imageUrl != null) console.log(`${np.title}: ${np.id}`);
      //  if (np.isGooglePlace == true) console.log(`${np.title}: ${np.id}`);
      return new Place(
        np.title,
        np.subtitle,
        np.details,
        np.address,
        np.lat,
        np.lng,
        np.phone,
        np.link,
        np.category,
        np.id,
        np.googleImage,
        np.privacy
      );
    });
    //falls ich die aktuelle Position habe, sortiere ich in der Liste ausgerichtet danach, ansonsten nach dem Dom
    const lat = parseFloat(context.getters.currentPosition.lat) ?? 50.941302;
    const lng = parseFloat(context.getters.currentPosition.lng) ?? 6.957189;
    places.sort((p1, p2) => {
      return p1.distTo(lat, lng) - p2.distTo(lat, lng);
    });

    context.commit("setPlaces", places);
    context.commit("setMyPlaces", true);
    context.commit("setFriend", null);

    // let place = places.find(p => p.title == "KVB Kiosk");
    // console.log(place)
    // for (let place of places) { //KD Wiederverwendbar, wenn man mit allen Places etwas machen möchte
    // context.dispatch("updatePlace", place)
    // console.log(place.title)
    // }
  },
  async loadFriendsPlaces(context, payload) {
    // let userId, firstname
    // [userId , firstname] = [payload.userId, payload.firstname]
    let userId = payload.userId.substring(6);
    const response = await fetch(
      `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places/friend/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      }
    ).catch(() => {
      throw "Fehler beim Laden der Nice Places. Bitte versuche es später erneut.";
    });

    if (!response.ok) {
      throw "Fehler beim Laden der Nice Places. Bitte versuche es später erneut.";
    }

    const responseData = await response.json();
    const places = responseData.map((np) => {
      return new Place(
        np.title,
        np.subtitle,
        np.details,
        np.address,
        np.lat,
        np.lng,
        np.phone,
        np.link,
        np.category,
        np.id,
        np.googleImage,
        np.privacy
      );
    });

    const lat = parseFloat(context.getters.currentPosition.lat) ?? 50.941302;
    const lng = parseFloat(context.getters.currentPosition.lng) ?? 6.957189;
    places.sort((p1, p2) => {
      return p1.distTo(lat, lng) - p2.distTo(lat, lng);
    });

    context.commit("setPlaces", places);
    context.commit("setMyPlaces", false);
    context.commit("setFriend", payload);
  },
  async deletePlace(context, payload) {
    await fetch(
      `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places/${payload}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      }
    ) //KD 210515 zum Fehlerhandling siehe Notizen: "Nice Places" oder im ReadMe
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        context.commit("deletePlace", payload);
      })
      .catch(() => {
        throw "Löschen nicht erfolgreich";
      });
  },
  async addPlace(context, newPlace) {
    // store values as String
    newPlace.lat = `${newPlace.lat}`
    newPlace.lng = `${newPlace.lng}`
    try {
      const response = await fetch(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places`,
        {
          method: "POST",
          body: JSON.stringify(newPlace),
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      if (!response.ok) {
        //für die 4xx- und 5xx-Fehler, die fetch nicht automatisch catched
        throw new Error();
      }
      const jsonResponse = await response.json();
      newPlace.id = jsonResponse.id;
      context.commit("addPlace", newPlace);
      console.log("------> ID:", newPlace.id)
    } catch (error) {
      throw "Fehler beim Hinzufügen eines Places";
    }
  },
  // copyPlace(context, place) {
  //   return fetch(
  //     `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places/copy`,
  //     {
  //       method: "POST",
  //       body: JSON.stringify(place),
  //       headers: {
  //         Authorization: `Bearer ${context.getters.token}`,
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       if(!response.ok) { // fetch doesn't catch 4xx an 5xx errors, so I have to check response
  //         return response.json().then(json => Promise.reject(json)) // throws an error with server message instead of generic message - used below *),
  //                                                                   //see https://dev.to/tabrez/how-to-handle-4xx-and-5xx-errors-in-javascript-fetch-14cf
  //                                                                   //(throw wouldn't work because it's an asynchronous callback, see https://stackoverflow.com/questions/33445415/javascript-promises-reject-vs-throw)
  //       }
  //       return response.json()
  //     })
  //     .catch((error) => {
  //       throw new Error(error.message ?? "Fehler beim Kopieren des Place‚") // catches network errors (fetch-standard) and 4xx and 5xx errors from above *)
  //     });
  // },
  async copyPlace(context, place) {
    let friendId = context.getters.friend.userId.substring(6);
    return await axios
      .post(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places/copy/${friendId}`,
        JSON.stringify(place),
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      )
      .then((result) => result.data)
      .catch((error) => {
        throw new Error(
          error.response?.data.message ?? "Fehler beim Kopieren des Places"
        );
      });
  },
  //KD 210517 Hier nun mit axios: geht schlanker und ich spare mir den "doppelten" catch
  async updatePlace(context, updatedPlace) {
    await axios
      .put(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places`,
        JSON.stringify(updatedPlace),
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      )
      .then(context.commit("updatePlace", updatedPlace))
      .catch(() => {
        throw "Fehler beim Ändern des Places";
      });
  },
  setCurrentPosition(context, payload) {
    context.commit("setCurrentPosition", payload);
  },
  setCenter(context, payload) {
    context.commit("setCenter", payload);
  },
  loadingComplete(context) {
    context.commit("loadingComplete");
  },
  setGeoCoding(context, payload) {
    context.commit("setGeoCoding", payload);
  },
  setMap(context, payload) {
    context.commit("setMap", payload);
  },
  setNearbyPlaces(context, payload) {
    context.commit("setNearbyPlaces", payload);
  },
  removeNearbyPlace(context, payload) {
    context.commit("removeNearbyPlace", payload);
  },
  updateNearbyPlace(context, payload) {
    context.commit("updateNearbyPlace", payload);
  },
};
