<template>
  <section class="container">
    <base-info-dialog
      :show="!!error"
      :title="'Fehler!'"
      @close="closeErrorDialog"
      >{{ error }}</base-info-dialog
    >
    <base-info-dialog
      :show="nicknameExits"
      :title="'Achtung!'"
      @close="closeErrorDialog"
      >{{ "Der Spitzname ist bereits vergeben. Bitte wähle einen anderen." }}</base-info-dialog
    >
    <base-card>
      <h2>Netzwerk der Nutzer</h2>
      Wir sind ein offenes Netzwerk, alle NutzerInnen sind mit ihrem Namen und Vornamen für andere sichtbar. Du kannst dann mit
      anderen Nutzern Freundschaften schließen und Nice Places teilen. Wenn du eine Freundschaftsanfrage erhältst, teilen wir dir dies in einer E-Mail an die Adresse, die du bei der Registrierung angegeben hast, mit. 
      Deine Freunde erhalten diese E-Mail-Adresse nicht. Wenn du damit einverstanden bist, gib deine
      Daten ein und du wirst Mitglied unseres Netzwerks. Im Netzwerk sprechen wir dich mit deinem Spitznamen an, dieser muss eindeutig sein.
      <user-form @save-data="saveData" @cancel="cancelForm"></user-form>
    </base-card>
  </section>
</template>

<script>
import axios from "axios";
import UserForm from "./UserForm.vue";

export default {
  async created() {
    await this.$store.dispatch("getUsers");
  },
  components: {
    UserForm,
  },
  data() {
    return {
      error: null,
      nicknameExits: false,
    };
  },
  methods: {
    // async saveData(newUser) {
    //   this.showSpinner = true;
    //   try {
    //     await axios
    //       .post(
    //         `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/users`,
    //         JSON.stringify(newUser),
    //         {
    //           headers: {
    //             Authorization: `Bearer ${this.$store.getters.token}`,
    //           },
    //         }
    //       )
    //       .catch((error) => {
    //         throw new Error(
    //           error.response?.data.message ?? "Fehler beim Anlegen des Nutzers"
    //         );
    //       });
    //     this.$router.replace("/friends");
    //   } catch (error) {
    //     this.error = error.message;
    //   } finally {
    //     this.showSpinner = false;
    //   }
    // },
    async saveData(newUser) {
      let users = this.$store.getters.users // instead of mapGetters
      if (users.map(u => u.nickname).includes(newUser.nickname)) {
        this.nicknameExits = true
        return
      }
      this.showSpinner = true;
      await axios
        .post(
          `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/users`,
          JSON.stringify(newUser),
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
        .then(() => this.$router.replace("/friends"))
        .catch((error) => this.error = error.response?.data.message ?? "Fehler beim Anlegen des Nutzers");
      this.showSpinner = false;
    },
    cancelForm() {
      this.$router.replace("/map");
    },
    closeErrorDialog() {
      this.error = null;
      this.nicknameExits = false;
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
}

@media (min-width: 768px) {
  section {
    min-width: 768px;
    max-width: 50vw;
    margin: auto;
  }
}
</style>
