<template>
  <base-card>
    <h2>Finde Plätze in der Nähe</h2>
    <div>
      <label class="standard" for="category">Kategorie</label>
    </div>
    <select v-model="type" id="type">
      <option value="restaurant">Restaurant</option>
      <option value="lodging">Hotel/Ferienwohnung</option>
      <option value="bar">Bar</option>
      <option value="cafe">Café</option>
      <option value="museum">Museum</option>
      <option value="point_of_interest">Sehenswertes</option>
      <option value="park">Park</option>
      <option value="supermarket">Supermarkt</option>
      <option value="bakery">Bäckerei</option>
      <option value="parking">Parkplatz</option>
      <option value="police">Polizei</option>
      <option value="train_station">Bahnhof</option>
      <option value="taxi_stand">Taxistand</option>
    </select>
    <div>
      <label class="standard">Radius</label>
    </div>
    <div class="radio">
      <div class="radio-block">
        <input type="radio" id="100" value="100" v-model="radius" />
        <label for="100">100 m</label>
        <input type="radio" id="500" value="500" v-model="radius" />
        <label for="500">500 m</label>
        <input type="radio" id="1500" value="1500" v-model="radius" />
        <label for="1500">1,5 km</label>
      </div>
      <div class="radio-block">
        <input type="radio" id="3000" value="3000" v-model="radius" />
        <label for="3000">3 km</label>
        <input type="radio" id="10000" value="10000" v-model="radius" />
        <label for="10000">10 km</label>
        <input type="radio" id="20000" value="20000" v-model="radius" />
        <label for="20000">20 km</label>
      </div>
    </div>
    <div><base-button @click.prevent="search">Suchen</base-button>
    <base-button @click.prevent="$emit('back')">Zurück</base-button></div>
  </base-card>
</template>

<script>
export default {
  activated() {
    this.radius = "1500";
    this.type = "restaurant";
  },
  emits: ["nearbySearch", "back"],
  data() {
    return {
      radius: "1500",
      type: "restaurant",
    };
  },
  methods: {
    search() {
      this.$emit("nearbySearch", this.radius, this.type);
    },
  },
};
</script>

<style scoped>
.standard {
  font-weight: bold;
  display: block;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

select {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

select,
.radio {
  margin-bottom: 1rem;
}

.radio-block {
  display: inline-block;
}


@media (min-width: 768px) {
  select {
    width: 50%;
  }
}
</style>
