export default {
  users(state) {
    return state.users;
  },
  friends(state) {
    return state.friends;
  },
  requestedIds(state) {
    return state.requestedIds
  }
};
