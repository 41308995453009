export default {
  places(state) {
    return state.places;
  },
  nearbyPlaces(state) {
    return state.nearbyPlaces;
  },
  currentPosition(state) {
    return state.currentPosition
  },
  center(state) {
    return state.center
  },
  loadingComplete(state) {
    return state.loadingComplete
  },
  geoCoding(state) {
    return state.geoCoding
  },
  map(state) {
    return state.map
  },
  myPlaces(state) {
    return state.myPlaces
  },
  friend(state) {
    return state.friend
  },
}