<template>
  <section class="auth">
    <base-card>
      <label>Bitte melde dich an oder registrier dich</label>
      <base-button @click.prevent="login">Anmelden / Registrieren</base-button>
      <p>{{ this.error }}</p>
    </base-card>
    <base-info-dialog
      :show="showVerifyDialog"
      :title="'Die Regisitrierung ist fast abgeschlossen'"
      @close="closeVerifyDialog"
    >Bitte verifiziere noch dein Konto über die E-Mail, die wir dir gerade zugeschickt haben. Anschließend kannst du dich anmelden.</base-info-dialog>
  </section>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      showVerifyDialog: false,
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("login", this.$auth0); 
        const verified = this.$store.getters.emailVerified
        if (!verified) {
          this.showVerifyDialog = true
        } else {
          this.showVerifyDialog = false
          this.$router.replace("/map"); 
        }
      } catch (err) {
        this.error =
          err.message ||
          "Fehler in der Authentifizierung, versuche es später erneut.";
      }
    },
    async closeVerifyDialog() {
      await this.$store.dispatch("logout", this.$auth0);
      this.showVerifyDialog = false
    }
  },
};
</script>

<style scoped>
section {
  margin: 1rem;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.8rem;
}

p {
  color: red
}

@media (min-width: 768px) {
  .auth {
    min-width: 768px;
    max-width: 90vw;
    margin: auto;
  }
}
</style>
