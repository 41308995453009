<template>
  <div class="info-card">
    <span class="title"> {{`${friendFirstname}s Places`}}  </span>
    <base-button class="my-places" @click.prevent="$emit('myPlaces')"
      >Meine Places anzeigen</base-button
    >
  </div>
</template>

<script>
export default {
  emits: ["myPlaces"],
  computed: {
    friendFirstname() {
      return this.$store.getters.friend.firstname;
    },
  },
};
</script>

<style scoped>

.title {
  color: red;
  font-weight: bold;
  font-size: x-large;
}

.my-places {
  margin: 0.1rem 0 0 2rem;
}

.info-card {
  display: flex;
  justify-content: center;
  border: solid lightgrey 1px;
  padding: 0.3rem;
  background-color: rgb(242, 241, 240);
}

@media (min-width: 768px) {
  .info-card {
    padding: 0.5rem;
  }
}
</style>
