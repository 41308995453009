<template>
  <!-- // KD 2105115
Dient als Info- und Error-Dialog
Da ich den Info-Dialog oft aufrufe und nicht immer den ganzen Sermon des Base-Dialog ausfüllen möchte,
habe ich den BaseInfoDialog geschaffen, der mit weniger Eingaben auskommt -->
  <base-dialog
    :show="show"
    :title="title"
    info
    closeCaption="Schließen"
    @close="$emit('close')"
    ><slot></slot
  ></base-dialog>
</template>

<script>
export default {
  props: ["show", "title"],
  emits: ["close"],
};
</script>