<template>
  <base-card>
    <h4>Liste filtern</h4>
    <input type="search" @input="filter" placeholder="mindestens zwei Buchstaben erforderlich" />
  </base-card>
</template>

<script>
export default {
  methods: {
    filter(event) {
      this.$emit("filterList", event.target.value.trim());
    },
  },
  emits: ["filterList"],
};
</script>

<style scoped>
input {
  width: 95%;
}

h4 {
  margin: 0.3rem 0;
}

input {
  border: solid lightgrey 1px;
}

input:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3f49e2;
}

</style>
