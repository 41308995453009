<template>
  <section class="container">
    <base-chat-dialog
      :show="willInputText"
      :title="chatTitle"
      @commit="sendMessage"
      @close="closeDialog"
    ></base-chat-dialog>
    <div class="chat">
      <div class="connection-info" :style="styleObject" />
      <div class="members">
        <h4>Teilnehmer</h4>
        <div v-if="membersAvailable" class="explain">Klick auf den Namen für private Nachricht</div>
        <ul>
          <li
            class="member"
            v-for="member in members"
            :key="member"
            @click="openPrivateInput(member)"
          >
            {{ member }}
          </li>
        </ul>
      </div>
      <div class="communication">
        <ul>
          <li class="row" v-for="row in chatRows" :key="row">
            <span v-html="row"></span>
          </li>
        </ul>
      </div>
      <div class="footer">
        <base-button class="buttons" v-if="!isConnected" @click="connect"
          >Verbinden</base-button
        >
        <div v-else>
          <base-button class="buttons" @click="openPublicInput"
            >An alle</base-button
          >
          <base-button @click="disconnect">Unterbrechen</base-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseChatDialog from "./BaseChatDialog.vue";
import { mapGetters } from "vuex";

const URL = "wss://w239tnf72g.execute-api.eu-central-1.amazonaws.com/dev";

export default {
  components: {
    BaseChatDialog,
  },
  async created() {
    await this.$store.dispatch("getUsers");
    this.nickname = this.users.find((u) => u.userId == this.userId).nickname;
  },
  data() {
    return {
      members: [],
      chatRows: [],
      isConnected: false,
      chatSocket: null,
      nickname: "",
      willInputText: false,
      chatTitle: "",
      recipient: "",
    };
  },
  methods: {
    connect() {
      this.chatSocket = new WebSocket(URL);
      if (this.chatSocket.readyState !== WebSocket.OPEN) {
        this.chatSocket.onopen = () => this.onSocketOpen();
        this.chatSocket.onclose = () => this.onSocketClose();
        this.chatSocket.onmessage = (event) => this.onSocketMessage(event);
      }
    },
    disconnect() {
      this.chatSocket.close();
    },
    onSocketOpen() {
      this.chatSocket.send(
        JSON.stringify({ action: "setName", nickname: this.nickname })
      );
      this.isConnected = true;
    },
    onSocketClose() {
      this.members = [];
      this.isConnected = false;
    },
    onSocketMessage(event) {
      let data = JSON.parse(event.data);
      if (data.members) {
        this.members = data.members;
      } else if (data.publicMessage) {
        this.chatRows.push(`<b>${data.publicMessage}</b>`);
      } else if (data.privateMessage) {
        this.chatRows.push(
          "<b style='color:red;'>Private Nachricht von " +
            data.privateMessage +
            "</b>"
        );
      } else if (data.systemMessage) {
        this.chatRows.push(`<i>${data.systemMessage}</i>`);
      }
    },
    sendMessage(message) {
      this.recipient == ""
        ? this.sendPublic(message)
        : this.sendPrivate(message);
    },
    openPrivateInput(member) {
      this.recipient = member;
      this.chatTitle = "Private Nachricht an " + member;
      this.willInputText = true;
    },
    sendPrivate(message) {
      this.chatSocket.send(
        JSON.stringify({
          action: "sendPrivate",
          message,
          to: this.recipient,
        })
      );
      this.chatRows.push(
          "<b style='color:red;'>Private Nachricht an " +
            this.recipient + ": " + message.toString() +
            "</b>"
        );
      this.closeDialog();
    },
    openPublicInput() {
      this.chatTitle = "Nachricht an alle";
      this.willInputText = true;
    },
    sendPublic(message) {
      this.chatSocket?.send(JSON.stringify({ action: "sendPublic", message }));
      this.closeDialog();
    },
    closeDialog() {
      this.recipient = "";
      this.willInputText = false;
    },
  },
  computed: {
    ...mapGetters(["users", "userId"]),
    styleObject() {
      return { backgroundColor: this.isConnected ? "#00da00" : "#e2e2e2" };
    },
    membersAvailable() {
      return this.members.length > 0
    }
  },
};
</script>

<style scoped>
.container {
  position: relative;
}
.chat {
  display: grid;
  grid-template-columns: 25vw auto;
  grid-template-areas:
    "members communication"
    "members footer";
  height: 72vh;
}

.footer {
  grid-area: footer;
}

.buttons {
  margin: 1rem;
}
.members {
  width: 25vw;
  background-color: #3f49e2;
  color: #ecec14;
  grid-area: members;
  overflow: auto;
}
.member {
  margin-left: 0.5rem;
  cursor: pointer;
  padding: 0.2rem;
}

h4,
.explain {
  margin-left: 0.5rem;
}

.explain {
  font-style: italic;
  font-size: xx-small;
  margin-right: 0.5rem;
}

.communication {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 0.5rem 0 0;
  grid-area: communication;
  min-height: 72vh;
  overflow: scroll;
  padding: 1rem;
}

.row {
  padding: 0.2rem;
}

ul {
  margin-left: 0;
  padding: 0;
}

li {
  list-style: none;
}

.connection-info {
  position: absolute;
  right: 9px;
  top: 2vh;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .chat {
    grid-template-columns: 10vw auto;
    height: 95vh;
  }

  .members {
    min-width: 7rem;
    width: 10vw;
  }

  .buttons {
    margin-left: 2.5rem;
  }
}
</style>
