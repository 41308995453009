<template>
  <!-- // KD 210511 zum rumspielen ;-)
Da ich den Delete-Dialog oft aufrufe und nicht immer den ganzen Sermon des Base-Dialog ausfüllen möchte,
habe ich den BaseDeleteDialog geschaffen, der mit weniger Eingaben auskommt (ob das so Sinn macht - keine Ahnung) -->
  <base-dialog
    :show="willDeletePlace"
    :title="titleCaption"
    :commitCaption="commitCaption"
    closeCaption="Abbrechen"
    @commit="$emit('commit')"
    @close="$emit('close')"
    >{{dialogText}}</base-dialog
  >
</template>

<script>
export default {
  props: ["willDeletePlace", "titleCaption", "dialogText", "commitCaption"],
  emits: ["commit", "close"],
};
</script>
