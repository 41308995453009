<template>
  <div class="container">
    <base-spinner v-if="showSpinner" class="dialog-spinner"></base-spinner>
    <base-info-dialog
      :show="!!error"
      :title="'Fehler'"
      @close="closeErrorDialog"
      >{{ error }}</base-info-dialog
    >
    <base-delete-dialog
      :show="willDeletePlace"
      :titleCaption="'Nice Place löschen?'"
      :dialogText="'Willst du den Nice Place wirklich löschen?'"
      :commitCaption="'Löschen'"
      @commit="deletePlace"
      @close="closeDeleteDialog"
    ></base-delete-dialog>
    <base-dialog
      :show="willAddPlace"
      title="Neuen Nice Place hinzufügen?"
      placeholder="Name des Nice Place"
      commitCaption="Speichern"
      closeCaption="Abbrechen"
      @commit="addPlace"
      @close="closeDialog"
    ></base-dialog>
    <place-routing
      :show="routing"
      :distance="distance"
      :duration="duration"
      :positionCounter="changePositionCounter"
      :accuracy="accuracy"
      @stopRouting="stopRouting"
    ></place-routing>
    <div class="map-view">
      <place-search
        v-if="!isNavigating && myPlaces"
        class="place-search"
        :update="updatePlaceSearch"
        @nearbySearch="nearbySearch"
        @setError="setError"
      ></place-search>
      <friend-info
        v-if="!isNavigating && !myPlaces"
        @myPlaces="loadMyPlaces"
      ></friend-info>
      <div v-if="!isNavigating" id="mapDiv" ref="mapDiv"></div>
      <base-spinner class="kd-spinner" v-else></base-spinner>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import MarkerClusterer from "@googlemaps/markerclustererplus";

import Place from "../../store/Place.js";
import PlaceSearch from "../../components/map/PlaceSearch.vue";
import PlaceRouting from "../../components/map/PlaceRouting.vue";
import FriendInfo from "../../pages/friends/FriendInfo.vue";

// const GOOGLE_MAPS_API_KEY = "AIzaSyDpY4KwxvQ03QxcspvJgN0sFB6yTBC7nGw";
const GOOGLE_MAPS_API_KEY = "AIzaSyAOQnhnpBT-_zPLSAPJC0NGMvrzUhJwgsI";
const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  libraries: ["places"],
});

//KD 210417 wenn ich die Map als data-Variable definiere, was richtig wäre, wird die Karte extrem langsam. Daher so
let map;

let crosshairsMarker;

//KD 210421 auch hier funktionert die Lösung mit der data-Variablen nicht: die Places lassen sich nicht alle von der Karte löschen.
let markerClusterer;

export default {
  components: { PlaceSearch, PlaceRouting, FriendInfo },
  data() {
    return {
      isNavigating: false,
      geoCoder: null,
      routing: false,
      distance: 0,
      duration: 0,
      directionsService: null,
      directionsRenderer: null,
      willAddPlace: false,
      newPlaceCoord: null,
      positionMarker: null,
      willDeletePlace: false,
      placeIdToDelete: null,
      error: "",
      changePositionCounter: 0,
      counter: 0,
      accuracy: 0,
      updatePlaceSearch: false,
      showSpinner: false,
    };
  },
  created() {
    //KD 210415 klappt bei Safari nur über https - also dort nur für die Produktion interessant
    if (navigator.geolocation) {
      this.isNavigating = true;
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const currentPosition = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          this.$store.dispatch("setCurrentPosition", currentPosition);
          this.$store.dispatch("setGeoCoding", true);
          this.loadPlaces();
          //   this.$store.dispatch("loadingComplete"); //KD wech
          // this.loadMap(); //KD wech
          // this.isNavigating = false; //KD wech
        },
        () => {
          this.loadPlaces(); //better here than in handleLocationError
          this.handleLocationError();
        },
        options
      );

      navigator.geolocation.watchPosition(
        (pos) => {
          this.positionChanged(pos);
        },
        () => {
          this.handleLocationError();
        },
        options
      );
    } else {
      this.handleLocationError();
    }
  },
  methods: {
    async loadMyPlaces() {
      // evtl. auch erneut loadPlaces()?
      this.showSpinner = true;
      await this.$store.dispatch("loadPlaces");
      this.showSpinner = false;
    },
    async loadPlaces() {
      try {
        // await this.$store.dispatch("loadPlacesFromJson");
        await this.$store.dispatch("loadPlaces");
        this.$store.dispatch("loadingComplete");
        await this.loadMap();
        this.isNavigating = false;
      } catch (error) {
        this.error = error;
      }
    },
    async loadMap() {
      loader
        .load()
        .then(() => {
          map = new window.google.maps.Map(this.$refs["mapDiv"], {
            center: this.$store.getters["currentPosition"],
            zoom: 16,
            options: { streetViewControl: false },
          });
          this.$store.dispatch("setMap", map);

          this.updatePlaceSearch = !this.updatePlaceSearch; //KD 220222 hack -> README.md
          this.geoCoder = new window.google.maps.Geocoder();
          this.directionsService = new window.google.maps.DirectionsService(
            map
          );
          this.directionsRenderer = new window.google.maps.DirectionsRenderer();
          this.directionsRenderer.setMap(map);

          this.showPlaces();
        })
        .catch((e) => {
          throw e;
        });
    },
    showPlaces() {
      //KD 21042 Wenn ich gleichzeitig nur ein InfoWindow auf dem Screen haben will, darf es nur ein
      // Window-Objekt geben. Daher die Definition hier, außerhalb der map-Schleife. Geöffnet werden
      // muss das Window dann in der onClick-Methode. Siehe auch hier:
      // https://developers.google.com/maps/documentation/javascript/infowindows
      let infoWindow = new window.google.maps.InfoWindow();

      this.drawAndHideControls(); // show "placeOnCrosshairs" only for my own Places

      this.clearMarkers();
      crosshairsMarker?.setMap(null);

      var crosshairsImage = {
        url: require("@/assets/crosshairs.png"),
        //  size: new window.google.maps.Size(63, 63),
        //  origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(32, 32), // marker anchor point (on the center of the marker instead on the bottom)
      };
      // var crossHairsShape = {
      //   coords: [32, 32, 32, 32], // 1px
      //   type: "rect", // rectangle
      // };

      crosshairsMarker = new window.google.maps.Marker({
        position: map.getCenter(),
        map: map,
        icon: crosshairsImage,
        // shape: crossHairsShape,
        optimized: false,
        zIndex: 5,
      });

      window.google.maps.event.addListener(
        map,
        "bounds_changed",
        this.centerCrosshairs
      );

      const markers = this.places.map((location) => {
        const marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            parseFloat(location.lat) ?? 0,
            parseFloat(location.lng) ?? 0
          ),
          label: {
            text: location.title ?? "",
            color: "#3f49e2",
          },
          zIndex: 10,
        });
        const contentString = this.myPlaces
          ? `<h2 id="firstHeading" class="firstHeading">${location.title}</h2>` +
            `<h3 id="secondHeading" class="thirdHeading">${
              location.subtitle ?? ""
            }</h3>` +
            `<p>${location.address ?? ""}</p>` +
            `<p><button style="width:2rem;margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="calcRoute('${location.lat}','${location.lng}','walk')"
          ><i class='fas fa-walking'></i></button>` +
            `<button style="width:2rem;margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="calcRoute('${location.lat}','${location.lng}','drive')"
          ><i class='fas fa-car'></i></button>` +
            `<button style="margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="calcRoute('${location.lat}','${location.lng}','bike')"
          ><i class='fas fa-biking'></i></button>` +
            `<button style="width:2rem;margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="askForDelete('${location.id}')"
          ><i class='fas fa-trash'></i></button>` +
            `<button style="width:2rem;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="editPlace('${location.id}')"
          ><i class='fas fa-arrow-right'></i></button></p>`
          : `<h2 id="firstHeading" class="firstHeading">${location.title}</h2>` +
            `<h3 id="secondHeading" class="thirdHeading">${
              location.subtitle ?? ""
            }</h3>` +
            `<p>${location.address ?? ""}</p>` +
            `<p><button style="width:2rem;margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="calcRoute('${location.lat}','${location.lng}','walk')"
          ><i class='fas fa-walking'></i></button>` +
            `<button style="width:2rem;margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="calcRoute('${location.lat}','${location.lng}','drive')"
          ><i class='fas fa-car'></i></button>` +
            `<button style="margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="calcRoute('${location.lat}','${location.lng}','bike')"
          ><i class='fas fa-biking'></i></button>` +
            // `<button style="width:2rem;margin-right:5pt;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            //   onclick="askForDelete('${location.id}')"
            // ><i class='fas fa-trash'></i></button>` +
            `<button style="width:2rem;background-color:#3f49e2;border-color:#3f49e2;color:#ecec14;border-radius:4px;"
            onclick="editPlace('${location.id}')"
          ><i class='fas fa-arrow-right'></i></button></p>`;

        //KD 210427 GENIAL - von hier:
        // https://stackoverflow.com/questions/53202059/how-to-trigger-a-function-using-click-inside-infowindow-of-google-maps-in-vue-j/66380299
        //KD "this" bezeichnet hier das große Vue-Objekt, das alle Daten und Methoden enthält. "this" ist in der folgenden Funktion anders gesetzt
        const that = this;
        window.editPlace = function (id) {
          that.$router.push(`/map/${id}?redirect=map`);
        };
        window.askForDelete = function (id) {
          that.askForDelete(id);
        };
        window.calcRoute = function (lat, lng, type) {
          that.calcRoute(lat, lng, type);
        };

        marker.addListener("click", () => {
          infoWindow.setContent(contentString);
          infoWindow.open(map, marker);
        });

        return marker;
      });

      markerClusterer = new MarkerClusterer(map, markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      });
    },
    centerCrosshairs() {
      crosshairsMarker.setPosition(map.getCenter());
    },

    async addPlace(placeTitle) {
      this.willAddPlace = false;
      this.showSpinner = true;
      const title = placeTitle.length > 0 ? placeTitle : "Neuer Nice Place";
      //KD 210518 Id is undefined, will be set from server
      const newPlace = new Place(
        title,
        "",
        "",
        "",
        this.newPlaceCoord.lat(),
        this.newPlaceCoord.lng(),
        "",
        "",
        "",
        null,
        null,
        "private"
      );
      if (this.geoCoding) {
        try {
          const address = await this.findAddress();
          newPlace.address = address;
        } catch {
          this.error = "Keine Adresse gefunden";
        }
      }
      try {
        await this.$store.dispatch("addPlace", newPlace);
      } catch (error) {
        this.error = error;
      } finally {
        this.showSpinner = false;
      }
    },
    findAddress() {
      //KD 210430 geocode gibt keinen Promise zurück, sondern arbeitet mit callback. Daher erzeuge ich den Promise.
      // https://gabrieleromanato.name/javascript-how-to-use-the-google-maps-api-with-promises-and-async-await
      return new Promise((resolve, reject) => {
        this.geoCoder.geocode(
          { location: this.newPlaceCoord },
          (results, status) => {
            if (status === "OK") {
              resolve(results[0].formatted_address);
            } else {
              reject(status);
            }
          }
        );
      });
    },
    closeDialog() {
      this.willAddPlace = false;
    },
    clearMarkers() {
      markerClusterer?.clearMarkers();
      // this.currentMarkers.length = 0;
    },
    setPositionMarker() {
      this.positionMarker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          this.$store.getters["currentPosition"]
        ),
        clickable: false,
        icon: new window.google.maps.MarkerImage(
          "//maps.gstatic.com/mapfiles/mobile/mobileimgs2.png",
          new window.google.maps.Size(22, 22), // size
          new window.google.maps.Point(0, 18), // origin
          new window.google.maps.Point(11, 11) // anchor
        ),
        shadow: null,
        zIndex: 999,
        map,
      });
    },
    positionChanged(pos) {
      const position = {
        lat: pos.coords.latitude ?? 0,
        lng: pos.coords.longitude ?? 0,
      };
      this.accuracy = pos.coords.accuracy.toFixed(2);
      this.changePositionCounter++;
      if (this.accuracy > 17) {
        // -> README.md
        if (this.counter == 2) {
          // zwei Fehlschüsse werden erlaubt, aber vergessen
          this.counter = 0;
          this.resetPosition(); // neuer Aufruf von getPosition (statt watchPosition)
          return;
        } else {
          this.counter++; // zwei Fehlschüsse werden erlaubt, aber vergessen
          return;
        }
      }
      this.counter = 0;
      this.positionMarker && this.positionMarker.setPosition(position);
      this.$store.dispatch("setCurrentPosition", position);
    },
    resetPosition() {
      if (navigator.geolocation) {
        var options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const currentPosition = {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            };
            this.positionMarker &&
              this.positionMarker.setPosition(currentPosition);
            this.$store.dispatch("setGeoCoding", true);
            this.$store.dispatch("setCurrentPosition", currentPosition);
          },
          () => {
            this.handleLocationError();
          },
          options
        );
      } else {
        this.handleLocationError();
      }
    },
    handleLocationError() {
      // handleLocationError(browserHasGeolocation) {
      this.$store.dispatch("setGeoCoding", false);
      //this.loadPlaces(); //now in created
      // this.error = browserHasGeolocation
      //   ? "Achtung - Standortlokation fehlgeschlagen"
      //   : "Dein Browser unterstützt keine Standortlokation";
    },
    drawAndHideControls() {
      let ctrlLength =
        map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].length;
      for (let i = 0; i < ctrlLength; i++) {
        map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].pop();
      }

      if (this.geoCoding) {
        this.drawCenterMapOnPosition();
        this.setPositionMarker();
      }

      if (this.myPlaces) this.drawPlaceOnCrosshairs();
    },
    drawCenterMapOnPosition() {
      const image = require("../../assets/crosshairs_grey_50.png");
      const locationButton = this.addButton(image);
      map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
        locationButton
      );
      locationButton.addEventListener("click", () => {
        if (this.geoCoding) {
          const pos = this.$store.getters.currentPosition;
          const center = new window.google.maps.LatLng(pos.lat, pos.lng);
          this.$store.dispatch("setCenter", center);
        }
      });
    },
    drawPlaceOnCrosshairs() {
      const image = require("../../assets/position_grey_50.png");
      const locationButton = this.addButton(image);
      map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
        locationButton
      );
      locationButton.addEventListener("click", () => {
        this.newPlaceCoord = map.getCenter();
        this.willAddPlace = true;
      });
    },
    nearbySearch() {
      // let url =
      //   "wss://bd318jlw14.execute-api.eu-central-1.amazonaws.com/production";
      // let exampleSocket = new WebSocket(url);

      // exampleSocket.onopen = () => {
      //   exampleSocket.send(
      //     JSON.stringify({ action: "sendmessage", message: "FC, wer sonst!" })
      //   );
      // };

      // exampleSocket.onmessage = (event) => {
      //   console.log(event.data);
      // };

      this.$store.dispatch("setCenter", map.getCenter()); //because not every move of map changes the center state. But I need the center state in NearbySearch
      this.$router.push("/places/nearby");
    },
    //https://stackoverflow.com/questions/24952593/how-to-add-my-location-button-in-google-maps/36457742:
    addButton(image) {
      var controlDiv = document.createElement("div");

      var firstChild = document.createElement("button");
      firstChild.style.backgroundColor = "#fff";
      firstChild.style.border = "none";
      firstChild.style.outline = "none";
      firstChild.style.width = "36px";
      firstChild.style.height = "36px";
      firstChild.style.borderRadius = "2px";
      firstChild.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
      firstChild.style.cursor = "pointer";
      firstChild.style.marginRight = "10px";
      firstChild.style.padding = "0px";
      // firstChild.title = "Your Location";
      controlDiv.appendChild(firstChild);

      var secondChild = document.createElement("div");
      secondChild.style.margin = "5px";
      secondChild.style.width = "25px";
      secondChild.style.height = "25px";
      //secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
      secondChild.style.backgroundImage = `url(${image})`;
      secondChild.style.backgroundSize = "25px 25px";
      secondChild.style.backgroundPosition = "0px 0px";
      secondChild.style.backgroundRepeat = "no-repeat";
      //    secondChild.id = "you_location_img";
      firstChild.appendChild(secondChild);

      // controlDiv.index = 1;
      return controlDiv;
    },

    async deletePlace() {
      this.willDeletePlace = false;
      this.showSpinner = true;
      try {
        await this.$store.dispatch("deletePlace", this.placeIdToDelete);
      } catch (error) {
        this.error = error;
      } finally {
        this.showSpinner = false;
      }
    },
    askForDelete(id) {
      this.placeIdToDelete = id;
      this.willDeletePlace = true;
    },
    closeDeleteDialog() {
      this.willDeletePlace = false;
    },
    setError(error) {
      this.error = error;
    },
    closeErrorDialog() {
      this.error = "";
    },
    calcRoute(lat, lng, type) {
      let mode;
      switch (type) {
        case "walk":
          mode = window.google.maps.TravelMode.WALKING;
          break;
        case "bike":
          mode = window.google.maps.TravelMode.BICYCLING;
          break;
        default:
          mode = window.google.maps.TravelMode.DRIVING;
      }
      var request = {
        origin: this.$store.getters.currentPosition,
        destination: `${lat},${lng}`,
        travelMode: mode,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      };

      this.directionsService.route(request, (result) => {
        if (result.status == "OK") {
          this.routing = true;
          const meters = result.routes[0].legs[0].distance.value;
          this.distance = meters.toString().toKM();
          const seconds = result.routes[0].legs[0].duration.value;
          this.duration = seconds.toString().toDDHHMM();

          this.directionsRenderer.setDirections(result);
        } else {
          this.error = "Route kann nicht ermittelt werden";
          this.duration = "";
        }
      });
    },
    stopRouting() {
      this.routing = false;
      this.directionsRenderer.setDirections({ routes: [] });
    },
  },

  computed: {
    center() {
      return this.$store.getters.center;
    },
    places() {
      return this.$store.getters.places;
    },
    geoCoding() {
      return this.$store.getters.geoCoding;
    },
    myPlaces() {
      return this.$store.getters.myPlaces;
    },
    friendFirstname() {
      return this.myPlaces ? "" : this.$store.getters.friend.firstname;
    },
  },
  watch: {
    center() {
      // refocus the map every time the center state changes
      map.setCenter(this.center);
      map.setZoom(17);
    },
    places() {
      !!map && this.showPlaces();
    },
    $route() {
      // this will be called any time the route changes
      if (this.$route.query.route != null) {
        let routingDestination = this.places.find(
          (p) => p.id === this.$route.query.id
        );
        if (routingDestination != null) {
          this.calcRoute(
            routingDestination.lat,
            routingDestination.lng,
            this.$route.query.route
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.map-view {
  height: 100%; /* from router-view, -> App.vue */
  display: grid;
  grid-template-rows: 2.8rem auto;
  grid-template-areas:
    "search"
    "map";
}

#mapDiv {
  grid-area: map;
}

.place-search,
friend-info {
  grid-area: search;
}

.container {
  position: relative;
}

.kd-spinner {
  grid-area: map; /* map is not vsisible, so spinner takes its place */
}

@media (min-width: 768px) {
  .map-view {
    grid-template-rows: 3rem auto; /* search and map */
  }
}
</style>
