// import { createApp, defineAsyncComponent } from 'vue';
import { createApp } from "vue";
import { createAuth0 } from "@auth0/auth0-vue";

//KD 210928 von hier: https://www.youtube.com/watch?v=MoDIpTuRWfM
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faArrowRight,
  faArrowLeft,
  faMap,
  faCamera,
  faEdit,
  faWalking,
  faBiking,
  faCar,
  faTimes,
  faLocationArrow,
  faSignOutAlt,
  faBars,
  faCheck,
  faUserFriends,
  faMapMarker
} from "@fortawesome/free-solid-svg-icons";

import store from "./store/index.js"
import router from "./router.js";

import BaseCard from "./components/ui/BaseCard.vue";
import BaseButton from "./components/ui/BaseButton.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import BaseDeleteDialog from "./components/ui/BaseDeleteDialog.vue";
import BaseInfoDialog from "./components/ui/BaseInfoDialog.vue";

import App from "./App.vue";

// const BaseDialog = defineAsyncComponent(() => import('./components/ui/BaseDialog.vue'))
// const BaseDeleteDialog = defineAsyncComponent(() => import('./components/ui/BaseDeleteDialog.vue'))

library.add(
  faTrash,
  faArrowRight,
  faArrowLeft,
  faMap,
  faCamera,
  faEdit,
  faWalking,
  faBiking,
  faCar,
  faTimes,
  faLocationArrow,
  faSignOutAlt,
  faBars,
  faCheck,
  faUserFriends,
  faMapMarker
);

//KD 2202216 formatting distance and duration informaition for routing
String.prototype.toDDHHMM = function () {
  var sec_num = parseInt(this, 10);
  var days = Math.floor(sec_num / 86400);
  var hours = Math.floor((sec_num - days * 86400) / 3600);
  var minutes = Math.floor((sec_num - days * 86400 - hours * 3600) / 60);

  let dString = days > 0 ? days.toString() + " t " : "";
  let hString =
    hours > 0
      ? hours.toString() + " h "
      : days > 0
      ? hours.toString() + " h "
      : "";
  let mString = minutes.toString() + " min ";

  return dString + hString + mString;
};

String.prototype.toKM = function () {
  var meter = parseInt(this, 10);
  var km = meter / 1000;
  return meter > 999
    ? (km < 25 ? km.toFixed(1) : km.toFixed(0)) + " km"
    : meter + " m";
};

const app = createApp(App);

app.use(
  createAuth0({
    domain: "dev-pw1zjuyq.eu.auth0.com",
    client_id: "3duK6MQbpA1XnPmD2inWRgF9cMPIHj0S",
    redirect_uri: window.location.origin + "/map",
  })
);
app.use(router);
app.use(store);

app.component("base-card", BaseCard);
app.component("base-button", BaseButton);
app.component("base-spinner", BaseSpinner);
app.component("base-dialog", BaseDialog);
app.component("base-delete-dialog", BaseDeleteDialog);
app.component("base-info-dialog", BaseInfoDialog);

app.component("fa", FontAwesomeIcon).mount("#app");
