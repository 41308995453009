<template>
  <section class="container">
    <base-spinner class="dialog-spinner" v-if="showSpinner"></base-spinner>
    <base-delete-dialog
      :show="willDeletePlace"
      :titleCaption="'Nice Place löschen?'"
      :dialogText="'Willst du den Nice Place wirklich löschen?'"
      :commitCaption="'Löschen'"
      @commit="deletePlace"
      @close="closeDeleteDialog"
    ></base-delete-dialog>
    <base-delete-dialog
      :show="willDeleteImage"
      :titleCaption="'Foto löschen?'"
      :dialogText="'Willst du das Foto wirklich löschen?'"
      :commitCaption="'Löschen'"
      @commit="deleteImage"
      @close="closeDeleteDialog"
    ></base-delete-dialog>
    <base-info-dialog
      :show="!!error"
      :title="'Fehler!'"
      @close="closeErrorDialog"
      >{{ error }}</base-info-dialog
    >
    <base-card>
      <h2>Nice Place</h2>
      <place-form
        @save-data="saveData"
        @copy-place="copyPlace"
        @cancel="cancelForm"
        @deletePlace="askForDeletePlace"
        @uploadImage="uploadImage"
        @route="route"
        :placeId="id"
        :placeCopied="placeCopied"
      ></place-form>
    </base-card>
    <ul>
      <place-image
        v-for="image in images"
        :key="image.imageUrl"
        :imageId="image.imageId"
        :imageUrl="image.imageUrl"
        @showDetails="showImageDetails"
        @deleteImage="askForDeleteImage"
      ></place-image>
    </ul>
  </section>
</template>

<script>
import PlaceForm from "./PlaceForm.vue";
import PlaceImage from "./PlaceImage.vue";

export default {
  components: {
    PlaceForm,
    PlaceImage,
  },
  props: ["id"],
  created() {
    this.loadImages();
  },
  activated() {
    this.placeCopied = false;
    this.loadImages();
  },
  deactivated() {
    this.images = [];
  },
  data() {
    return {
      error: null,
      willDeletePlace: false,
      willDeleteImage: false,
      imageIdToDelete: null,
      images: [],
      showSpinner: false,
      placeCopied: false,
    };
  },
  methods: {
    async saveData(updatedPlace) {
      this.showSpinner = true;
      try {
        await this.$store.dispatch("updatePlace", updatedPlace);
        this.closeForm();
      } catch (error) {
        this.error = error;
      } finally {
        this.showSpinner = false;
      }
    },
    async copyPlace(place) {
      this.showSpinner = true;
      try {
        place.privacy = "private"
        await this.$store.dispatch("copyPlace", place);
        this.placeCopied=true;
      } catch(error) {
        this.error = error.message;
      } finally {
        this.showSpinner = false;
      }
    },
    cancelForm() {
      this.closeForm(); 
    },
    async deletePlace() {
      this.willDeletePlace = false;
      this.showSpinner = true;
      try {
        await this.$store.dispatch("deletePlace", this.id);
        this.closeForm();
      } catch (error) {
        this.error = error;
      } finally {
        this.showSpinner = false;
      }
    },
    closeErrorDialog() {
      this.error = null;
    },
    askForDeletePlace() {
      this.willDeletePlace = true;
    },
    askForDeleteImage(imageId) {
      this.imageIdToDelete = imageId;
      this.willDeleteImage = true;
    },
    closeDeleteDialog() {
      this.willDeletePlace = false;
      this.willDeleteImage = false;
    },
    closeForm() {
      this.$router.replace(this.redirectUrl);
    },
    route(type) {
      this.$router.push(`/map?route=${type}&id=${this.id}`);
    },
    async loadImages() {
      let response
      if (this.$store.getters.myPlaces) {
        response = await this.myImages()
      } else {
        response = await this.friendsImages()
      }
      this.images = await response.json();
      this.loadGoogleImage();
    },
    async myImages() {
      let response = await fetch(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places/${this.id}/images`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        } 
      );
      return response;
    },
    async friendsImages() {
      let friendId = this.$store.getters.friend.userId.substring(6)
      let response = await fetch(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/friend/${friendId}/places/${this.id}/images`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        } 
      );
      return response;
    },
    loadGoogleImage() {
      let places = this.$store.getters.places;
      let place = places.find((p) => p.id === this.id);
      let imageInfo = place.googleImage;
      if (imageInfo && imageInfo.length > 0) {
        let placesService = new window.google.maps.places.PlacesService(
          this.$store.getters.map
        );
        const request = {
          placeId: this.id,
          fields: ["photos"],
        };
        placesService.getDetails(request, this.detailsCallback);
      }
    },
    detailsCallback(googlePlace, status) {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        if (googlePlace.photos[0]?.getUrl() != null) {
          this.images.push({ imageUrl: googlePlace.photos[0]?.getUrl() }); // if google image is available, append it
        }
      }
    },
    async uploadImage(file) {
      this.showSpinner = true;
      let uploadInfo;
      try {
        uploadInfo = await this.createImage(file.name);
        const optimizedPhoto = await this.optimizedPhoto(file);
        await fetch(uploadInfo.uploadUrl, {
          method: "PUT",
          body: optimizedPhoto,
        });
      } catch {
        this.showSpinner = false;
      }
      this.showSpinner = false;

      this.images.unshift(uploadInfo.newItem);
    },
    async createImage(fileName) {
      //KD 210523 create image entry in DB and get presigned url for upload image
      const reply = await fetch(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/places/${this.id}/images`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
          body: JSON.stringify({
            title: fileName,
          }),
        }
      );
      return await reply.json();
    },
    async deleteImage() {
      this.willDeleteImage = false;
      this.showSpinner = true;
      try {
        await fetch(
          `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/images/${this.imageIdToDelete}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );
        this.images = this.images.filter(
          (image) => image.imageId != this.imageIdToDelete
        );
      } catch (error) {
        this.error = error;
      } finally {
        this.showSpinner = false;
      }
    },
    async readPhoto(photo) {
      const canvas = document.createElement("canvas");
      const img = document.createElement("img");

      // create img element from File object
      img.src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsDataURL(photo);
      });
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      // draw image in canvas element
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height);

      return canvas;
    },
    scaleCanvas(canvas, scale) {
      const scaledCanvas = document.createElement("canvas");
      scaledCanvas.width = canvas.width * scale;
      scaledCanvas.height = canvas.height * scale;

      scaledCanvas
        .getContext("2d")
        .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

      return scaledCanvas;
    },

    //KD 210525 siehe https://dev.to/taylorbeeston/resizing-images-client-side-with-vanilla-js-4ng2
    // und https://gist.github.com/TaylorBeeston/48148e61eb4a9db89d7482081e8714ca:
    async optimizedPhoto(photo) {
      const MAX_WIDTH = 1600;
      const QUALITY = 0.9;
      let canvas = await this.readPhoto(photo);

      while (canvas.width >= 2 * MAX_WIDTH) {
        canvas = this.scaleCanvas(canvas, 0.5);
      }

      if (canvas.width > MAX_WIDTH) {
        canvas = this.scaleCanvas(canvas, MAX_WIDTH / canvas.width);
      }

      return new Promise((resolve) => {
        canvas.toBlob(resolve, "image/jpeg", QUALITY);
      });
    },
    showImageDetails(imageUrl) {
      //KD 220329 router props without url params:
      // https://stackoverflow.com/questions/50998305/vue-is-there-a-way-to-pass-data-between-routes-without-url-params
      let placeId = this.id;
      let redirect = this.$route.query.redirect || "map";
      this.$router.push({
        name: "image",
        params: { imageUrl, placeId, redirect },
      });
    },
  },
  computed: {
    redirectUrl() {
      return "/" + (this.$route.query.redirect || "map");
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}

.container {
  position: relative;
}

@media (min-width: 768px) {
  section {
    min-width: 768px;
    max-width: 90vw;
    margin: auto;
  }
}
</style>
