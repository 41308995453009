<template>
  <section>
    <base-card>
      <h2>Seite nicht vorhanden</h2>
      <p>
        Diese Seite gibt es nicht. Vielleicht möchtest du die 
        <router-link to="/map">Karte</router-link> sehen.
      </p>
    </base-card>
  </section>
</template>