export default {
  methods: {
    category(categoryNumber) {
      switch (categoryNumber) {
        case "1":
          return "Hotel/Ferienwohnung";
        case "2":
          return "Museum";
        case "3":
          return "Kneipe/Bar/Café/Eisdiele";
        case "4":
          return "Landschaft/Natur/Sehenswertes";
        case "5":
          return "Theater";
        case "6":
          return "Restaurant";
        case "8":
          return "Biergarten";
        case "9":
          return "Freunde";
        case "10":
          return "Kultur";
        case "11":
          return "Einkaufen";
        case "12":
          return "Favoriten";
        default:
          return "Sonstiges";
      }
    },
  },
}