export default {
  setPlaces(state, payload) {
    state.places = payload;
  },
  setNearbyPlaces(state, payload) {
    state.nearbyPlaces = payload;
  },
  deletePlace(state, payload) {
    state.places = state.places.filter((place) => place.id !== payload); 
  },
  addPlace(state, payload) {
    // push geht hier nicht, da Vue dann die Veränderung nicht triggert. Der places-Array muss neu gesetzt werden.
    state.places = [ payload, ...state.places ]  
  },
  updatePlace(state, payload) {
    const places = state.places.filter(p => p.id !== payload.id )
    state.places = [ payload, ...places ]  
  },
  setCurrentPosition ( state, payload) {
    state.currentPosition = payload;
  },
  setCenter ( state, payload) {
    state.center = payload;
  },
  loadingComplete (state) {
    state.loadingComplete = true;
  },
  setGeoCoding (state, payload) {
    state.geoCoding = payload;
  },
  setMap (state, payload) {
    state.map = payload
  },
  removeNearbyPlace(state, payload) {
    state.nearbyPlaces = state.nearbyPlaces.filter((place) => place.id !== payload); 
  },
  updateNearbyPlace(state, payload) {
    const places = state.nearbyPlaces.filter(p => p.id !== payload.id )
    state.nearbyPlaces = [ payload, ...places ]  
  },
  setMyPlaces(state, payload) {
    state.myPlaces = payload
  },
  setFriend(state, payload) {
    state.friend = payload
  },
}