<template>
  <div>
    <form>
      <div class="form-control" :class="{ invalid: !firstnameIsValid }">
        <label for="firstname">Vorname</label>
        <input type="text" id="firstname" v-model.trim="firstname"/>
        <p v-if="!firstnameIsValid">Bitte einen Vornamen angeben</p>
      </div>
      <div class="form-control">
        <label for="lastname">Nachname</label>
        <input type="text" id="lastname" v-model.trim="lastname"/>
        <p v-if="!lastnameIsValid">Bitte einen Nachnamen angeben</p>
      </div>
      <div class="form-control">
        <label for="nickname">Spitzname (Kleinbuchstaben)</label>
        <input type="text" id="nickname" v-model.trim="nickname"/>
        <p v-if="!nicknameIsValid">Bitte einen Spitznamen angeben</p>
      </div>
      <p class="form-invalid" v-if="!formIsValid">
          Bitte zuerst die Fehler korrigieren.
        </p>
      <div class="buttons control-buttons">
        <base-button @click.prevent="$emit('cancel')">Abbrechen</base-button>
        <base-button @click.prevent="submitForm">Einverstanden</base-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  emits: ["save-data", "cancel"],
  data() {
    return {
      firstname: "",
      firstnameIsValid: true, //KD 220221 siehe auch Musterlösung_Schwarzmüller in Abschnitt 20
      lastname: "",
      lastnameIsValid: true,
      nickname: "",
      nicknameIsValid: true,
      formIsValid: true,
    };
  },
  watch: {
    //when values change, check validation
    firstname() {
      this.validateForm();
    },
    lastname() {
      this.validateForm();
    },
    nickname() {
      this.nickname = this.nickname.toLowerCase()
      this.validateForm();
    },
  },
  methods: {
    validateForm() {
      this.formIsValid = true;
      this.firstnameIsValid = true;
      this.lastnameIsValid = true;
      this.nicknameIsValid = true;
      if (this.firstname === "" || this.firstname.length < 2) {
        this.formIsValid = false;
        this.firstnameIsValid = false;
      }
      if (this.lastname === "" || this.lastname.length < 2) {
        this.formIsValid = false;
        this.lastnameIsValid = false;
      }
      if (this.nickname === "" || this.nickname.length < 2) {
        this.formIsValid = false;
        this.nicknameIsValid = false;
      }

    },
    submitForm() {
      this.validateForm();
      if (!this.formIsValid) {
        return;
      }

      let newUser = new Object();
      newUser.firstname = this.firstname
      newUser.lastname = this.lastname
      newUser.nickname = this.nickname
      
      this.$emit("save-data", newUser);   
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 0.2rem;
  color: red;
}

.form-invalid {
  margin-top: 0.5rem;
  margin-bottom: -0.2rem;
}

label {
  font-weight: bold;
  display: block;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3f49e2;
}

.invalid label {
  color: red;
}

.invalid input {
  border: 1px solid red;
}

.buttons {
  display: flex;
  flex-wrap: nowrap; /* //KD 210929 damit die Buttons auf meinem iPhone in einer Zeile stehen */
}

.control-buttons {
  margin-top: 0.5rem;
}
</style>
