<template>
  <!-- //KD 210516 habe nun auch die deletePlace-Funktionalität an die aufrufende Komponente delegiert.
  Dies erscheint mir sauberer, da nun die Form-Komponente lediglich für die Verabeitung der Eingabe 
  verantwortlich ist und keine weitere Funktionalität beinhaltet.
 -->
  <div>
    <!-- den :key benötige ich, damit sich der Base-Dialog neu aufbaut. Sonst wird ein alter verwendet
    siehe https://stackoverflow.com/questions/32106155/can-you-force-vue-js-to-reload-re-render -->
    <base-dialog
      :show="editLink"
      :inputValue="link"
      :key="link"
      title="Link bearbeiten"
      placeholder="Bitte Link eingeben"
      commitCaption="Speichern"
      closeCaption="Abbrechen"
      @commit="changeLink"
      @close="closeLinkDialog"
    ></base-dialog>
    <base-dialog
      :show="editPhone"
      :inputValue="phone"
      :key="phone"
      placeholder="Bitte Telefonnummer eingeben"
      title="Telefonnummer bearbeiten"
      commitCaption="Speichern"
      closeCaption="Abbrechen"
      @commit="changePhone"
      @close="closePhoneDialog"
    ></base-dialog>
    <form>
      <div class="form-control" :class="{ invalid: !titleIsValid }">
        <label for="title">Titel</label>
        <input type="text" id="title" v-model.trim="title" :disabled="!myPlaces"/>
        <!-- <label class="thin" v-else>{{title}}</label> -->
        <p v-if="!titleIsValid">Bitte einen Titel angeben</p>
      </div>
      <div class="form-control">
        <label for="subtitle">Untertitel</label>
        <input type="text" id="subtitle" v-model.trim="subtitle" :disabled="!myPlaces"/>
      </div>
      <div class="form-control" :class="{ invalid: !categoryIsValid }">
        <label for="category">Kategorie</label>
        <select v-model="category" id="category" :disabled="!myPlaces">
          <option value="6">Restaurant</option>
          <option value="3">Kneipe/Bar/Café/Eisdiele</option>
          <option value="8">Biergarten</option>
          <option value="4">Landschaft/Natur/Sehenswertes</option>
          <option value="1">Hotel/Ferienwohnung</option>
          <option value="10">Kultur</option>
          <option value="2">Museum</option>
          <option value="5">Theater</option>
          <option value="9">Freunde</option>
          <option value="11">Einkaufen</option>
          <option value="12">Favoriten</option>
          <option value="7">Sonstiges</option>
        </select>
        <p v-if="!categoryIsValid">Bitte eine Kategorie auswählen</p>
      </div>
      <div class="form-control">
        <label for="details">Details</label>
        <textarea id="details" rows="3" v-model.trim="details" :disabled="!myPlaces"></textarea>
      </div>
      <div v-if="hasAddress">
        <label>Adresse:</label>
        <div>{{ placeToEdit.address }}</div>
      </div>
      <div class="form-control">
        <div>
          <label v-if="(hasLink && !myPlaces) || myPlaces" for="link">Webseite</label>
          <base-button v-if="myPlaces" @click.prevent="editLink = true"
            ><fa icon="edit"
          /></base-button>
          <a v-bind:href="link" target="_blank"> {{ link }} </a>
        </div>
      </div>
      <div class="form-control">
        <div>
          <label v-if="(hasPhone && !myPlaces) || myPlaces" for="link">Telefonnummer:</label>
          <base-button v-if="myPlaces" @click.prevent="editPhone = true"
            ><fa icon="edit"
          /></base-button>
          <a v-bind:href="'tel:' + phone"> {{ phone }} </a>
        </div>
      </div>
      <label v-if="myPlaces" class="photo-label">Foto hinzufügen</label>
      <label v-if="myPlaces" class="photo-button" for="files">
        <fa icon="camera" style="font-size: 1rem" />
      </label>
      <input
        id="files"
        type="file"
        style="visibility: hidden"
        name="image"
        accept="image/*"
        @change="handleImage"
      />
      <label class="routing-label">Navigation</label>
      <div>
        <div class="buttons">
          <base-button @click.prevent="$emit('route', 'walk')"
            ><fa icon="walking"
          /></base-button>
          <base-button @click.prevent="$emit('route', 'drive')"
            ><fa icon="car"
          /></base-button>
          <base-button @click.prevent="$emit('route', 'bike')"
            ><fa icon="biking"
          /></base-button>
        </div>
        <p class="form-invalid" v-if="!formIsValid">
          Bitte zuerst die Fehler korrigieren.
        </p>
      </div>

      <div v-if="myPlaces" class="privacy">
        <input
          id="privacy"
          type="checkbox"
          v-model="friendsPlace"
        />
        <label v-if="myPlaces" for="area-frontend">Freunde dürfen den Place sehen</label>
      </div>

      <div class="buttons control-buttons">
        <base-button @click.prevent="$emit('deletePlace')" v-if="myPlaces">Löschen</base-button>
        <base-button @click.prevent="$emit('cancel')">Abbrechen</base-button>
        <base-button @click.prevent="submitForm"  v-if="myPlaces">Sichern</base-button>
        <base-button @click.prevent="copyPlace"  v-else>Zu meinen Places hinzufügen</base-button>
      </div>
      <div class="copied" v-if="placeCopied">Place wurde erfolgreich übernommen</div>
    </form>
  </div>
</template>

<script>
import Place from "../../store/Place.js";
export default {
  props: ["placeId", "placeCopied"],
  mounted() {
    this.fillForm();
  },
  activated() {
    this.fillForm();
  },
  emits: ["save-data", "cancel", "deletePlace", "uploadImage", "route", "copy-place"],
  data() {
    return {
      title: "",
      titleIsValid: true, //KD 220221 siehe auch Musterlösung_Schwarzmüller in Abschnitt 20
      subtitle: "",
      category: "",
      categoryIsValid: true,
      details: "",
      link: "",
      phone: "",
      formIsValid: true,
      editLink: false,
      editPhone: false,
      friendsPlace: false,
    };
  },
  computed: {
    hasAddress() {
      return !!this.placeToEdit?.address;
    },
    places() {
      return this.$store.getters.places;
    },
    placeToEdit() {
      return this.places.find((p) => p.id === this.placeId);
    },
    myPlaces() {
      return this.$store.getters.myPlaces
    },
    hasSubtitle() {
      return this.subtitle.length > 0
    },
    hasLink() {
      return this.link.length > 0
    },
    hasPhone() {
      return this.phone.length > 0
    },
  },
  watch: {
    //when values change, check validation
    category() {
      this.validateForm();
    },
    title() {
      this.validateForm();
    },
  },
  methods: {
    fillForm() {
      this.title = this.placeToEdit.title;
      this.subtitle = this.placeToEdit.subtitle;
      this.category = this.placeToEdit.category;
      this.details = this.placeToEdit.details;
      this.link = this.placeToEdit.link ?? "";
      this.phone = this.placeToEdit.phone ?? "";
      this.friendsPlace = this.placeToEdit.privacy == "friends";
    },
    validateForm() {
      this.formIsValid = true;
      this.titleIsValid = true;
      this.categoryIsValid = true;
      if (this.title === "") {
        this.formIsValid = false;
        this.titleIsValid = false;
      }
      if (this.category === "") {
        this.formIsValid = false;
        this.categoryIsValid = false;
      }
    },
    submitForm() {
      this.validateForm();
      if (!this.formIsValid) {
        return;
      }

      const updatedPlace = new Place();

      updatedPlace.title = this.title;
      updatedPlace.subtitle = this.subtitle;
      updatedPlace.category = this.category;
      updatedPlace.details = this.details;
      updatedPlace.link = this.link;
      updatedPlace.phone = this.phone;
      updatedPlace.address = this.placeToEdit.address;
      updatedPlace.lat = this.placeToEdit.lat;
      updatedPlace.lng = this.placeToEdit.lng;
      updatedPlace.id = this.placeToEdit.id;
      updatedPlace.googleImage = this.placeToEdit.googleImage;
      updatedPlace.privacy = this.friendsPlace ? "friends" : "private";

      this.$emit("save-data", updatedPlace);
    },
    copyPlace() {
      this.$emit("copy-place", this.placeToEdit)
    },
    handleImage(event) {
      const file = event.target.files[0];

      this.$emit("uploadImage", file);
    },
    closeLinkDialog() {
      this.editLink = false;
    },
    changeLink(linkString) {
      this.link = linkString;
      this.editLink = false;
    },
    closePhoneDialog() {
      this.editPhone = false;
    },
    changePhone(phoneString) {
      this.phone = phoneString;
      this.editPhone = false;
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 0.2rem;
  color: red;
}

.form-invalid {
  margin-top: 0.5rem;
  margin-bottom: -0.2rem;
}

label {
  font-weight: bold;
  display: block;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.thin {
  font-weight: normal;
}

input,
textarea,
select {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus,
select:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3f49e2;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea,
.invalid select {
  border: 1px solid red;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  accent-color: red;
  transform: scale(1.7);
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"] + label {
  display: inline;
  margin: 0 0 0 0.5rem;
}

.privacy {
  margin: 1.5rem 0 2rem 0;
}

.photo-button {
  background-color: #3f49e2;
  border: 1px solid #3f49e2;
  padding: 0.1rem 0.6rem;
  color: #ecec14;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 0.2rem;
  margin-bottom: 0;
  display: inline-block;
}

.photo-label {
  margin-bottom: 0;
}

.routing-label {
  margin-top: -1rem;
}

.photo-button:hover,
.photo-button:active {
  background-color: #1e27a8;
  border-color: #1e27a8;
}

.buttons {
  display: flex;
  flex-wrap: nowrap; /* //KD 210929 damit die Buttons auf meinem iPhone in einer Zeile stehen */
}

.control-buttons {
  margin-top: 0.5rem;
}

a {
  margin-right: 1rem;
}

.copied {
  color: #3f49e2;
  font-weight: bold;
}
</style>
