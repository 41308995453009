<template>
  <div class="kd-body">
    <!-- //KD 210912 Hier mache ich böse Sachen - siehe README.md -->
    <the-header></the-header>
    <router-view class="kd-router-view" v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";

export default {
  name: "App",
  components: {
    TheHeader,
  },
  created() {
    this.$store.dispatch("tryLogin", this.$auth0)
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}

.dialog-spinner {
  position: absolute;
  top: 50%;
  z-index: 20;
}
</style>


<style scoped>
/* //KD 210912 Zum Grid-Konstrukt auf dieser Seite: siehe README.md */
div .kd-body {
  margin: 0;
  display: grid;
  grid-template-rows: 10vh auto; /* header and rest - sychronize with media-query in TheHeader.vue  */
  grid-template-areas:
    "."
    "main";
}

.kd-router-view {
  height: 78vh; /* from kd-body - to leave room on page bottom for browser menu */
  grid-area: main;
}

/*@media (min-height: 51rem) { /* sychronize with media-query in TheHeader.vue */
@media (min-width: 768px) {
  .kd-router-view {
    height: 93vh; /* no room necesarry for browser menu */
  }

  /* sychronize with media-query in TheHeader.vue */
  div .kd-body {
    grid-template-rows: 7vh auto;
  }
}
</style>
