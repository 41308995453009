<template>
  <transition name="info">
    <div class="result" v-if="show">
      <div class="routing-info">
        <div>{{ duration }}({{ distance }})</div>
        <div>{{ `${positionCounter}: ${accuracy}` }}</div>
      </div>
      <div class="button">
        <base-button @click.prevent="$emit('stopRouting')"
          >Navigation beenden</base-button
        >
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["show", "distance", "duration", "positionCounter", "accuracy"],
  emits: ["stopRouting"],
};
</script>

<style scoped>
.result {
  position: fixed;
  bottom: 0px;
  width: 12rem;
  z-index: 10;
  background-color: rgb(147, 185, 250);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.routing-info {
  padding: 0.5rem 0.7rem;
  margin-bottom: 0.2rem;
}

.button {
  text-align: center;
}

.info-enter-from,
.info-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.info-enter-active {
  transition: all 0.3s ease-out;
}

.info-leave-active {
  transition: all 0.3s ease-in;
}

.info-enter-to,
.info-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
