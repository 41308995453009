<template>
  <teleport to="body">
    <div v-if="show" @click="close" class="backdrop"></div>
    <transition name="dialog">
      <dialog open v-if="show">
        <header>
          <h2>{{ title }}</h2>
        </header>
        <section>
          <input
            v-if="placeholder"
            ref="input"
            @keyup.enter="commit()"
            class="input-field"
            :placeholder="placeholder"
            type="text"
            v-model.trim="changedValue"
          />
        </section>
        <div class="message"><slot></slot></div>
        <menu>
          <base-button @click="close">{{ closeCaption }}</base-button>
          <base-button v-if="!info" @click="commit">{{
            commitCaption
          }}</base-button>
        </menu>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  updated() {
    this.$nextTick(() => {
      if (this.$refs.input) this.$refs.input.focus();
    });
    this.changedValue = "";
  },
  data() {
    return {
      changedValue: this.inputValue,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    inputValue: {
      type: String,
      required: false,
    },
    commitCaption: {
      type: String,
      required: false,
    },
    closeCaption: {
      type: String,
      required: true,
    },
    info: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["close", "commit"],
  methods: {
    close() {
      this.$emit("close");
    },
    commit() {
      this.$emit("commit", this.changedValue ?? "");
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden; /* //KD 210926 damit der Header nicht rausragt */
  background-color: white;
}

header {
  background-color: #3f49e2;
  color: #ecec14;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

.input-field {
  width: 60%;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

.message {
  padding: 0 1rem;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
