import axios from "axios";

export default {
  async getUser(context) {
    try {
      const response = await axios.get(
        "https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/user",
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      // let user = response.data;
      console.log(response)
      // context.commit("setUsers", users);
    } catch (error) {
      throw "Fehler beim Laden eines Benutzers";
    }
  },
  async getUsers(context) {
    try {
      const response = await axios.get(
        "https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/users",
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      let users = response.data;
      context.commit("setUsers", users);
    } catch (error) {
      throw "Fehler beim Laden der Benutzer";
    }
  },
  async getFriends(context) {
    try {
      const response = await axios.get(
        "https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/friends",
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      let friends = response.data;
      context.commit("setFriends", friends);
    } catch (error) {
      throw "Fehler beim Laden der Freunde";
    }
  },
  async getRequestedIds(context) {
    try {
      const response = await axios.get(
        "https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/friends/requestids",
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          },
        }
      );
      let requestedIds = response.data;
      context.commit("setRequestedIds", requestedIds);
    } catch (error) {
      throw "Fehler beim Laden meiner Freundschaftsanfragen";
    }
  },
  async requestFriendship(context, payload) {
    try {
      const response = await axios.put(
        `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/friends/request/${payload}`,
        {}, // axios.put needs a body
        {
          headers: {
            Authorization: `Bearer ${context.getters.token}`,
          }
        }
      );
      let requestedId = response.data;
      context.commit("requestFriendship", requestedId);
    } catch(error) {
      throw "Freundschaftsanfrage nicht erfolgreich";
    }
  },
  // async requestFriendship(context, payload) { 
  //   await fetch(
  //     `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/friends/request/${payload}`,
  //     {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${context.getters.token}`,
  //       },
  //     }
  //   ) //KD 210515 zum Fehlerhandling siehe Notizen: "Nice Places" oder im ReadMe
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error();
  //       }
  //       context.commit("requestFriendship", `auth0|${payload}`);
  //     })
  //     .catch(() => {
  //       throw "Freundschaftsanfrage nicht erfolgreich";
  //     });
  // },
  // die folgende Methode wird sowohl zum Beenden einer bestehenden Freundschaft als auch zum Ablehnen oder
  // zurückziehen einer Freundschftsanfrage verwendet.
  async cancelFriendship(context, payload) { 
    await fetch(
      `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/friends/cancel/${payload}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      }
    ) //KD 210515 zum Fehlerhandling siehe Notizen: "Nice Places" oder im ReadMe
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        context.commit("cancelFriendship", payload);
      })
      .catch(() => {
        throw "Löschen nicht erfolgreich";
      });
  },
  async commitRequest(context, payload) {
    await fetch(
      `https://fghlw4k3k9.execute-api.eu-central-1.amazonaws.com/dev/friends/commit/${payload}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
        },
      }
    ) //KD 210515 zum Fehlerhandling siehe Notizen: "Nice Places" oder im ReadMe
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        context.commit("commitRequest", payload);
      })
      .catch(() => {
        throw "Annahme der Freundschaftsanfrage nicht erfolgreich";
      });
  },
};
