import { createRouter, createWebHistory } from "vue-router";

import Map from "./pages/maps/Map.vue";

import store from "./store/index.js";

import PlacesList from "./pages/places/PlacesList.vue";
import PlaceDetails from "./components/map/PlaceDetails.vue";
import NearbyPlaces from "./pages/places/NearbyPlaces.vue";
import NearbyPlaceDetail from "./components/places/NearbyPlaceDetails.vue";
import PlaceImageDetails from "./components/map/PlaceImageDetails.vue";
import FriendsList from "./pages/friends/FriendsList.vue"
import UserDetails from "./pages/friends/UserDetails.vue"
import FriendsAdd from "./pages/friends/FriendsAdd.vue"
import Chat from "./pages/chat/Chat.vue"
import UserAuth from "./pages/auth/UserAuth.vue";
import NotFound from "./pages/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/map" },
    {
      path: "/map",
      component: Map,
      meta: { requiresAuth: true },
    },
    {
      path: "/map/:id",
      component: PlaceDetails,
      props: true,
      beforeEnter: (_, from, next) => {
        console.log(from.fullPath.substring(0,5))
        const start = from.fullPath.substring(0,5)
        const length = from.fullPath.length;
        const end = from.fullPath.substring(length-6,length);
        const condition = start === "/map/" && end === "/image"

        if (!(from.fullPath === "/map") && !(from.fullPath === "/places") && !condition) {
          next("/map");
        } else {
          next();
        }
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/map/:id/image",
      name: "image",
      component: PlaceImageDetails,
      props: true,
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/places",
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      component: PlacesList,
      meta: { requiresLoadingComplete: true, requiresAuth: true },
    },
    {
      path: "/places/nearby",
      component: NearbyPlaces,
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      meta: { requiresLoadingComplete: true, requiresAuth: true },
    },
    {
      path: "/places/nearby/:id",
      component: NearbyPlaceDetail,
      props: true,
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/friends",
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      component: FriendsList,
      meta: { requiresLoadingComplete: true, requiresAuth: true },
    },
    {
      path: "/chat",
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      component: Chat,
      meta: { requiresLoadingComplete: true, requiresAuth: true },
    },
    {
      path: "/users",
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      component: FriendsAdd,
      meta: { requiresLoadingComplete: true, requiresAuth: true },
    },
    {
      path: "/user",
      beforeEnter: (_, from, next) => {
        if (from.fullPath === "/") {
          next("/map");
        } else {
          next();
        }
      },
      component: UserDetails,
      meta: { requiresLoadingComplete: true, requiresAuth: true },
    },
    { path: "/auth", component: UserAuth,  meta: { requiresUnauth: true }, },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

//KD 210418 die Places werden in /map geladen. Hiermit verhindere ich, dass der user
//  direkt die places-url ansteuert, wenn die Places noch nicht geladen sind.
// Natürlich könnte ich die Places auch in App.vue laden. Dann würde diese Datei aber
// recht groß. Da ich ohnehin (wie in der App) zuerst die Map zeigen möchte, dieser Weg:
router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/auth");
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated){
    next("/map");
  } else {
    next()
  }
});

export default router;
