import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      map: null,
      myPlaces: true,
      friend: null,
      places: [],
      nearbyPlaces: [],
      //falls ich die aktuelle Position nicht habe, wird der Default Dom genutzt:
      currentPosition: {
        lat: 50.941302,
        lng: 6.957189,
      },
      center: {
        lat: 50.941302,
        lng: 6.957189,
      },
      loadingComplete: false,
      geoCoding: false,
    };
  },
  mutations,
  actions,
  getters,
}
