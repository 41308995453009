<template>
  <button :disabled="isDisabled" v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: '/'
    }
  }
}
</script>

<style scoped>
button {
  /* text-decoration: none; */
  /* padding: 0.25rem 0.25rem; */
  font: inherit;
  background-color: #3f49e2;
  border: 1px solid #3f49e2;
  color: #ecec14;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

/* a:hover,
a:active, */
button:hover,
button:active {
  background-color: #1e27a8;
  border-color: #1e27a8;
}

button:disabled {
  opacity: 0.5;
}

.flat {
  background-color: transparent;
  /* color: #3f49e2; */
  color: rgba(0, 0, 0, 0.6);
  border: none;
}

.outline {
  background-color: transparent;
  border-color: #1e27a8;
  color: #1e27a8;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  /* background-color: #1e27a831; */
  background-color: rgba(0, 0, 0, 0.1);
}
</style>