<template>
  <div class="friends-list">
    <base-spinner v-show="showSpinner" class="dialog-spinner"></base-spinner>
    <base-button class="back-button" @click="back"
      >zurück</base-button
    >
    <h2 v-if="requestsPending">Deine Anfragen</h2>
    <ul v-if="requestsPending">
      <requests-list-item
        @cancelRequest="cancel"
        v-for="request in friendsRequested"
        :key="request.nickname"
        :request="request"
      ></requests-list-item>
    </ul>
    <h2 v-if="usersPending">Nicht angefragt</h2>
    <div v-if="usersPending">(Zur Anfrage klicke <span><fa icon="user-friends"
          /></span>)</div>
    <ul v-if="usersPending">
      <friends-add-item
        @requestFriendship="request"
        v-for="user in usersRemaining"
        :key="user.nickname"
        :user="user"
      ></friends-add-item>
    </ul>
  </div>
</template>

<script>
import FriendsAddItem from "./FriendsAddItem.vue";
import RequestsListItem from "./RequestsListItem.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  async created() {
    this.showSpinner = true;
    await this.$store.dispatch("getUsers");
    await this.$store.dispatch("getRequestedIds");
    this.showSpinner = false;
  },
  components: {
    FriendsAddItem,
    RequestsListItem,
  },
  data() {
    return {
      showSpinner: false,
    };
  },
  methods: {
    ...mapActions(["requestFriendship", "cancelFriendship"]),
    async cancel(userId) {
      this.showSpinner = true;
      await this.cancelFriendship(userId.substring(6));
      this.showSpinner = false;
    },
    async request(userId) {
      this.showSpinner = true;
      await this.requestFriendship(userId.substring(6));
      this.showSpinner = false;
    },
    back() {
      this.$router.push("/friends")
    },
  },
  computed: {
    ...mapGetters(["users", "friends", "userId", "requestedIds"]),
    friendIds() {
      let friendIds = this.friends.map((f) => f.userId);
      friendIds.push(this.userId); //won't see myself in list, so declare myself as friend
      return friendIds;
    },
    usersRemaining() {
      return this.users.filter((u) => !(this.friendIds.includes(u.userId) || this.requestedIds.includes(u.userId)));
    },
    usersPending() {
      return this.usersRemaining.length > 0;
    },
    requestsPending() {
      return this.friendsRequested.length > 0;
    },
    friendsRequested() {
      return this.users.filter((u) => this.requestedIds.includes(u.userId))
    },
  },
};
</script>

<style scoped>
ul {
  border: 1px solid rgba(211, 211, 211, 0.3);
  border-radius: 12px;
  margin-bottom: 3rem;
  list-style-type: none;
  padding: 0;
}

.friends-list {
  background-color: white;
  position: relative;
  margin: 1.5rem;
}

.back-button {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .friends-list {
    min-width: 768px;
    max-width: 90vw;
    margin: auto;
  }
}
</style>
