import Place from "../store/Place.js";
export default {
  methods: {
    createPlace(googlePlace) {
      if (googlePlace == null) return null;
      return new Place(
        googlePlace.name,
        "",
        "",
        googlePlace.formatted_address,
        googlePlace.geometry.location.lat(),
        googlePlace.geometry.location.lng(),
        googlePlace.international_phone_number ?? "",
        googlePlace.website ?? "",
        this.getPlaceType(googlePlace) ?? "7",
        googlePlace.place_id,
        googlePlace.photos ? (googlePlace.photos[0]?.getUrl() ?? null) : null,
        "private"
      );
    },
    getPlaceType(result) {
      let type = result.types[0];
      if (
        type == "tourist_attraction" ||
        type == "point_of_interest" ||
        type == "establishment"
      ) {
        type = result.types[1] ?? type;
      }
      if (type == null) return "7";
      switch (type) {
        case "art_gallery":
          return "2";
        case "bakery":
          return "11";
        case "bicycle_store":
          return "11";
        case "bar":
          return "3";
        case "book_store":
          return "10";
        case "cafe":
          return "3";
        case "church":
          return "10";
        case "clothing_store":
          return "11";
        case "convinience_store":
          return "11";
        case "department_store":
          return "11";
        case "drugstore":
          return "11";
        case "electronics_store":
          return "11";
        case "furniture_store":
          return "11";
        case "florist":
          return "11";
        case "hardware_store":
          return "11";
        case "hindu_temple":
          return "10";
        case "home_goods_store":
          return "11";
        case "jewelry_store":
          return "11";
        case "library":
          return "10";
        case "liquor_store":
          return "11";
        case "lodging":
          return "1";
        case "mosque":
          return "10";
        case "museum":
          return "2";
        case "park":
          return "4";
        case "pharmacy":
          return "11";
        case "restaurant":
          return "6";
        case "shoe_store":
          return "11";
        case "shopping_mall":
          return "11";
        case "store":
          return "11";
        case "supermarket":
          return "11";
        case "synagogue":
          return "10";
        case "tourist_attraction":
          return "4";
        case "point_of_interest":
          return "4";
        default:
          return "7";
      }
    },
  },
};
