export default {
  setUsers(state, payload) {
    state.users = payload;
  },
  setFriends(state, payload) {
    state.friends = payload;
  },
  setRequestedIds(state, payload) {
    state.requestedIds = payload
  },
  cancelFriendship(state, payload) {
    state.friends = state.friends.filter(
      (friend) => friend.userId !== `auth0|${payload}`
    );
    state.requestedIds = state.requestedIds.filter((r) => r !== `auth0|${payload}`)
  },
  commitRequest(state, payload) {
    state.friends = state.friends.map((friend) => {
      if (friend.userId === `auth0|${payload}`) {
        friend.status = "committed";
        return friend;
      } else {
        return friend;
      }
    });
    },
  requestFriendship(state, payload) {
    state.requestedIds = [ payload, ...state.requestedIds ]
    },
};
