<template>
  <section class="container">
    <base-spinner v-if="showSpinner" class="dialog-spinner"></base-spinner>
    <base-card>
      <div>
        <h2>{{ nearbyPlace.title }}</h2>
        <div>
          <label>Adresse:</label>
          <div>{{ address }}</div>
        </div>
        <div>
          <label>Kategorie:</label>
          <div>{{ categoryString }}</div>
        </div>
        <div>
          <label for="link">Webseite: </label>
          <a v-bind:href="link" target="_blank"> {{ link }} </a>
        </div>
        <div>
          <label for="link">Telefonnummer:</label>
          <a v-bind:href="'tel:' + phone"> {{ phone }} </a>
        </div>
      </div>
      <div class="buttons control-buttons">
        <base-button @click="back">Zurück</base-button>
        <base-button :isDisabled="alreadyStored" @click="save"
          >Neuen Place speichern</base-button
        >
        <div v-if="alreadyStored">Place wurde bereits gespeichert</div>
      </div>
    </base-card>
    <ul>
      <!-- not all places (even if they are operational) have an image, therefore v-if -->
      <place-image
        v-if="imageUrl" 
        :imageUrl="imageUrl"
        @showDetails="showImageDetails"
      ></place-image>
    </ul>
  </section>
</template>

<script>
import PlaceImage from "../map/PlaceImage.vue";
import categoryMixin from "../../mixins/category.js";
import BaseSpinner from "../ui/BaseSpinner.vue";

export default {
  components: {
    PlaceImage,
    BaseSpinner,
  },
  data() {
    return {
      showSpinner: false,
    };
  },
  mixins: [categoryMixin],
  props: ["id"],
  methods: {
    back() {
      this.$router.push("/places/nearby");
    },
    showImageDetails(imageUrl) {
      //KD 220329 router props without url params:
      // https://stackoverflow.com/questions/50998305/vue-is-there-a-way-to-pass-data-between-routes-without-url-params
      let placeId = this.id;
      let redirect = "";
      this.$router.push({
        name: "image",
        params: { imageUrl, placeId, redirect },
      });
    },
    async save() {
      this.showSpinner = true;
      try {
        await this.$store.dispatch("addPlace", this.nearbyPlace);
        this.back();
      } catch (error) {
        console.log("FÄHLER!!!");
        // this.$emit("setError", "Fehler beim Anlegen des Nice Place");
        return;
      } finally {
        this.showSpinner = false;
      }
    },
  },
  computed: {
    nearbyPlace() {
      let nearbyPlaces = this.$store.getters.nearbyPlaces;
      return nearbyPlaces.find((p) => p.id === this.id);
    },
    link() {
      return this.nearbyPlace.link;
    },
    phone() {
      return this.nearbyPlace.phone;
    },
    address() {
      return this.nearbyPlace.address;
    },
    imageUrl() {
      return this.nearbyPlace.googleImage;
    },
    categoryString() {
      const catNo = this.nearbyPlace.category;
      return this.category(catNo);
    },
    alreadyStored() {
      let places = this.$store.getters.places;
      let storedPlace = places.find((p) => p.id === this.id);
      return storedPlace != null;
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
}

label {
  font-weight: bold;
  display: block;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

div,
a {
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  padding: 0;
}

@media (min-width: 768px) {
  section {
    min-width: 768px;
    max-width: 90vw;
    margin: auto;
  }
}
</style>
