<template>
  <div class="container" >
    <base-button class="flat" @click="back">
      <fa icon="arrow-left"
    /></base-button>
    <img :src="imageUrl" :style="imageSize" alt="Kein Bild" />
  </div>
</template>

<script>
export default {
  created() {
    let portrait = window.matchMedia("(orientation: portrait)");

    portrait.addEventListener("change", function(e) {
      if (e.matches) {
        this.factor = 0.9; // Portrait mode
      } else {
        this.factor = 0.93; // Landscape
      }
    });
    // screen.orientation.addEventListener("change", function() {
    //   this.factor = this.factor == 0.9 ? 0.93 : 0.9;
    // });
  },
  data() {
    return {
      factor: 1.0,
    };
  },
  props: ["imageUrl", "placeId", "redirect"],
  methods: {
    back() {
      if (this.redirect != "") {
        this.$router.push(`/map/${this.placeId}?redirect=${this.redirect}`)
      } else {
        this.$router.push(`/places/nearby/${this.placeId}`)
      }
    },
  },
  computed: {
    imageSize() {
      let w = window.innerWidth;
      let h = this.factor * window.innerHeight; //minus TheHeader
      return `width:${w}px; height:${h}px`;
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  margin-top: 10vh;
  margin: auto;
}

.flat {
  /* background-color: white; */
  position: absolute;
  top: 10vh;
  left: 1rem;
}

img {
  object-fit: contain;
}

@media (min-width: 768px) {
  .container {
    margin-top: 7vh;
  }
}
</style>
