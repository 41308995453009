<template>
  <div class="place-list">
    <base-spinner v-show="showSpinner" class="dialog-spinner"></base-spinner>
    <base-spinner v-show="loading" class="dialog-spinner"></base-spinner>
    <base-info-dialog
      :show="!!error"
      :title="'Fehler'"
      @close="closeErrorDialog"
      >{{ error }}</base-info-dialog
    >
    <section class="place-filter">
      <filter-list
        :key="renderSearch"
        @filter-list="setFilterString"
      ></filter-list>
    </section>
    <div class="toggle-list" v-if="places.length > 100">
      <p>{{ list.text }}</p>
      <base-button @click="toggleListLength">{{ list.buttonText }}</base-button>
    </div>
    <ul class="places-list">
      <place-list-item
        @deletePlace="removePlace"
        @updatePlace="updatePlace"
        @showOnMap="showOnMap"
        v-for="place in filteredPlaces"
        :key="place.id"
        :place="place"
      ></place-list-item>
    </ul>
  </div>
</template>

<script>
import PlaceListItem from "../../components/places/PlaceListItem.vue";
import FilterList from "../../components/ui/FilterList.vue";

import { mapGetters, mapActions } from "vuex";
import BaseSpinner from "../../components/ui/BaseSpinner.vue";

export default {
  components: {
    PlaceListItem,
    FilterList,
    BaseSpinner,
  },
  data() {
    return {
      renderSearch: 0,
      filterString: "",
      loading: false,
      list: {
        length: 100,
        text: "Ohne Filterkriterien werden maximal 100 Places angezeigt",
        buttonText: "Alle anzeigen",
      },
      error: null,
      showSpinner: false,
    };
  },
  methods: {
    ...mapActions(["deletePlace"]),
    async removePlace(id) {
      this.showSpinner = true;
      try {
        await this.deletePlace(id);
      } catch (error) {
        this.error = error;
      } finally {
        this.showSpinner = false;
      }
    },
    updatePlace(placeId) {
      this.$router.push(`/map/${placeId}?redirect=places`);
    },
    setFilterString(filterString) {
      this.filterString = filterString.toLowerCase();
    },
    //KD 210427 ob es klüger ist, die hier zu machen als in der PlaceListItem-Komponent (siehe da), weiß ich nicht...
    showOnMap(placeId) {
      const place = this.places.find((p) => p.id === placeId);
      const center = new window.google.maps.LatLng(place.lat, place.lng);
      this.$store.dispatch("setCenter", center);
      this.$router.push("/map");
    },
    closeErrorDialog() {
      this.error = null;
    },
    toggleListLength() {
      this.loading = true;
      this.renderSearch++; // to update place-filter component (with an empty input field)
      //KD 220131 hack to show spinner and render search field before list is updated (-> Readme.md):
      // nextTick didn'*'t work :-(
      setTimeout(() => {
        this.filterString = "";
        this.updateList();
      }, 1);
    },
    updateList() {
      this.loading = false;
      if (this.list.length === 0) {
        this.list = {
          length: 100,
          text: "Ohne Filterkriterien werden maximal 100 Places angezeigt",
          buttonText: "Alle anzeigen",
        };
      } else {
        this.list = {
          length: 0,
          text: "Alle Places werden angezeigt",
          buttonText: "100 anzeigen",
        };
      }
    },
  },
  computed: {
    ...mapGetters(["places"]),
    filteredPlaces() {
      let filtered;
      if (this.filterString.length < 2) {
        filtered =
          this.list.length === 0
            ? this.places
            : this.places.slice(0, this.list.length);
      } else {
        filtered = this.places.filter((place) => {
          return (
            place.title?.toLowerCase().includes(this.filterString) ||
            place.subtitle?.toLowerCase().includes(this.filterString) ||
            place.details?.toLowerCase().includes(this.filterString) ||
            place.address?.toLowerCase().includes(this.filterString)
          );
        });
      }

      const pos = this.$store.getters.currentPosition;
      const lat = parseFloat(pos.lat) ?? 50.941302;
      const lng = parseFloat(pos.lng) ?? 6.957189;
      const sorted = filtered.sort((p1, p2) => {
        return p1.distTo(lat, lng) - p2.distTo(lat, lng);
      });
      return sorted;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

.places-list {
  border-radius: 12px;
  border: 1px solid rgba(211, 211, 211, 0.3);
}

.toggle-list {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.place-list {
  background-color: white;
  position: relative;
}

@media (min-width: 768px) {
  .place-list {
    min-width: 768px;
    max-width: 90vw;
    margin: auto;
  }

  .place-filter {
    width: 50%;
    text-align: left;
  }

  .toggle-list {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
</style>
